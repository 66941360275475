import { API } from 'utils/api';

export const signup = async (loginPayload) => {
  const response = await API({}, { Authorization: '' }).post('auth/register', loginPayload);
  return response.data;
};

export const login = async (loginPayload) => {
  const response = await API({}, { Authorization: '' }).post('auth/login', loginPayload);
  return response.data;
};

export const resendEmail = async (email) => {
  const response = await API({}, { Authorization: '' }).post('auth/resendRegisterConfirmEmail', { email });
  return response.data;
};

export const forgotPassword = async (email) => {
  const response = await API({}, { Authorization: '' }).post('auth/forgotPassword', { email });
  return response.data;
};

export const resetPassword = async (password, token) => {
  const response = await API({}, { Authorization: '' }).post('auth/resetPassword', { password, token });
  return response.data;
};

export const verifySignup = async (key) => {
  const response = await API({}, { Authorization: '' }).get('auth/confirm', {
    params: { key },
  });
  return response.data;
};

export const getMe = async () => {
  const response = await API().get('auth/me');
  return response.data;
};

export const updateSettings = async (param) => {
  const response = await API().put('auth/settings', param);
  return response.data;
};

export const updateProfile = async (param) => {
  const response = await API().put('auth/profile', param);
  return response.data;
};

export const changePassword = async (param) => {
  const response = await API().put('auth/changePassword', param);
  return response.data;
};
