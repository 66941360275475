/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { OnboardingLayout } from 'layout';
import { LoginForm, ProFormText } from '@ant-design/pro-components';
import { useHistory, useLocation } from 'react-router-dom';
import { resetPassword } from 'api';
import { Card, message, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const ResetPasswordPage = () => {
  const history = useHistory();
  let query = useQuery();
  const token = query.get('token');
  const [isSuccess, setIsSuccess] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        history.push('/login');
      }, 5000);
    }
    return () => {};
  }, [isSuccess]);

  const onSubmit = async (params) => {
    try {
      await resetPassword(params.password, token);
      setIsSuccess(true);
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error?.response?.data?.message || error.message,
      });
    }
  };

  return (
    <OnboardingLayout>
      {contextHolder}
      {isSuccess ? (
        <Card
          style={{
            width: 480,
            backgroundColor: 'rgba(10, 10, 10, 0.5)',
            backdropFilter: 'blur(4px)',
            height: 'fit-content',
            textAlign: 'center',
          }}
        >
          <p>Your password has been successfully reset.</p>
          <p>Now directing to the login page...</p>
        </Card>
      ) : (
        <LoginForm
          contentStyle={{
            minWidth: 360,
            maxWidth: '75vw',
            backgroundColor: 'rgba(10, 10, 10, 0.5)',
            backdropFilter: 'blur(4px)',
            color: '#eee!important',
            borderRadius: 6,
            boxShadow: '0px 0px 24px 0px rgba(0,0,0,0.1)',
            padding: '32px 32px 0 32px',
          }}
          onFinish={async (values) => {
            await onSubmit(values);
          }}
          actions={[
            <div key="login" style={{ textAlign: 'right' }}>
              <ProFormText key="loginLink">
                Go to &nbsp;
                <a href="/login" style={{ textDecoration: 'none' }}>
                  Login
                </a>
              </ProFormText>
              ,
            </div>,
          ]}
          submitter={{
            submitButtonProps: {
              style: {
                width: '100%',
              },
            },
            render: () => (
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  width: '100%',
                }}
              >
                Submit
              </Button>
            ),
          }}
        >
          <h1 style={{ textAlign: 'center' }}>Reset Password</h1>
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: 'large',
              prefix: <UserOutlined />,
            }}
            placeholder={'Password'}
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Password is required!',
              },
            ]}
          />
          <ProFormText.Password
            name="confirm-password"
            fieldProps={{
              size: 'large',
              prefix: <UserOutlined />,
            }}
            placeholder={'Confirm Password'}
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Confirm Password is required!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The passwords that you entered do not match!'));
                },
              }),
            ]}
          />
        </LoginForm>
      )}
    </OnboardingLayout>
  );
};
