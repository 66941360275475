import { API } from 'utils/api';
import axios from 'axios';
import { taskCollections } from './../Cupernicus/Tools/VisualizationPanel/CollectionSelection/AdvancedSearch/collectionFormConfig';
export const createTask = async (payload) => {
  const response = await API().post('task', payload);
  return response.data;
};

export const getTasks = async (params) => {
  const response = await API().get('task', { params });
  return response.data.data;
};

export const getTaskById = async (id) => {
  const response = await API().get(`task/id/${id}`);
  return response.data.data;
};

export const getAllTasks = async (params) => {
  const response = await API().get('task/all', { params });
  return response.data.data;
};
export const deleteTaskById = async (id) => {
  const response = await API().delete('task/' + id);
  return response.data;
};
export const updateTaskById = async (id, data) => {
  const response = await API().put('task/' + id, data);
  return response.data;
};
export const validateWithDates = async (startDate, endDate, cloudCover, bbox) => {
  const response = await API().get(
    `task/validate_date?startDate=${startDate}&endDate=${endDate}&cloudCover=${cloudCover}&bbox=${bbox}`,
  );
  return response.data;
};
export const getTaskMeta = async (taskId, offset = 0, limit = 10) => {
  const response = await API().get(`task/${taskId}/meta?offset=${offset}&limit=${limit}`);
  return response.data;
};

export const getTaskReport = async (yoloIds, location, fromDate, toDate, collectionIds) => {
  let collectionData = taskCollections[0].collections.map((col) => ({ id: col.id, label: col.label }));
  taskCollections[1].collections.forEach((col) => {
    col.instruments.forEach((ins) => {
      collectionData = [...collectionData, ...ins.productTypes.map((pt) => ({ id: pt.id, label: pt.label }))];
    });
  });
  function getLeafKeys(obj) {
    const keys = [];
    function findLeaves(node) {
      if (typeof node === 'object' && node !== null) {
        Object.keys(node).forEach((key) => {
          // Recursively find leaves
          if (typeof node[key] === 'object' && node[key] !== null) {
            findLeaves(node[key]);
          } else {
            // If the property is not an object, it's a leaf node
            keys.push(key);
          }
        });
      }
    }
    findLeaves(obj); // Start the recursive search
    return keys;
  }
  const ids = getLeafKeys(collectionIds);
  const names = collectionData
    .filter((item) => ids.includes(item.id))
    .map((it) => it.label)
    .join(',');
  const res = await axios.post(`${process.env.REACT_APP_LANGCHAIN_REST_URL}/report`, {
    yoloIds,
    location,
    fromDate,
    toDate,
    collections: names,
  });
  return res.data;
};
