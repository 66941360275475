import React from 'react';
import { ProConfigProvider } from '@ant-design/pro-components';
import bgImg from 'assets/onboarding-bg.png';
const Layout = ({ children }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${bgImg})`,
        width: '100vw',
        height: '100vh',
        backgroundSize: 'cover',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          overflow: 'hidden',
          height: '100%',
          // zIndex: 1,
          pointerEvents: 'none',
        }}
      >
        <video
          src={'https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr'}
          controls={false}
          autoPlay
          playsInline
          loop
          muted={true}
          crossOrigin="anonymous"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div> */}

      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          paddingTop: 200,
          justifyContent: 'center',
        }}
      >
        {children}
      </div>
    </div>
  );
};
export const OnboardingLayout = ({ children }) => {
  return (
    <ProConfigProvider dark>
      <Layout>{children}</Layout>
    </ProConfigProvider>
  );
};
