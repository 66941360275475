/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { OnboardingLayout } from 'layout';
import { useHistory } from 'react-router-dom';
import { forgotPassword } from 'api';
import { Card, message, Button } from 'antd';
import { LoginForm, ProFormText } from '@ant-design/pro-components';
import { MailOutlined } from '@ant-design/icons';

export const ForgotPasswordPage = () => {
  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        history.push('/login');
      }, 5000);
    }
    return () => {};
  }, [isSuccess]);

  const onSubmit = async (params) => {
    try {
      await forgotPassword(params.email);
      setIsSuccess(true);
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error?.response?.data?.message || error.message,
      });
    }
  };

  return (
    <OnboardingLayout>
      {contextHolder}
      {isSuccess ? (
        <Card
          style={{
            width: 480,
            backgroundColor: 'rgba(10, 10, 10, 0.5)',
            backdropFilter: 'blur(4px)',
            height: 'fit-content',
            textAlign: 'center',
          }}
        >
          An email has been sent to your email address. Please check your inbox and click verification link to
          reset your password.
        </Card>
      ) : (
        <LoginForm
          contentStyle={{
            minWidth: 360,
            maxWidth: '75vw',
            backgroundColor: 'rgba(10, 10, 10, 0.5)',
            backdropFilter: 'blur(4px)',
            color: '#eee!important',
            borderRadius: 6,
            boxShadow: '0px 0px 24px 0px rgba(0,0,0,0.1)',
            padding: '32px 32px 0 32px',
          }}
          subTitle={<h2>Forgot Password</h2>}
          onFinish={async (values) => {
            await onSubmit(values);
          }}
          actions={[
            <div style={{ textAlign: 'right' }}>
              <ProFormText key="loginLink">
                Go to &nbsp;
                <a href="/login" style={{ textDecoration: 'none' }}>
                  Login
                </a>
              </ProFormText>
              ,
            </div>,
          ]}
          submitter={{
            render: () => (
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  width: '100%',
                }}
              >
                Submit
              </Button>
            ),
          }}
        >
          <h1 style={{ textAlign: 'center' }}>Forgot Password</h1>
          <ProFormText
            name="email"
            fieldProps={{
              size: 'large',
              prefix: <MailOutlined />,
            }}
            placeholder={'Email'}
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Email is required',
              },
            ]}
          />
        </LoginForm>
      )}
    </OnboardingLayout>
  );
};
