import React from 'react';

const MeasureIcon = () => (
  <svg
    id="svg2"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      verticalAlign: 'middle',
      paddingBottom: 1,
    }}
  >
    <g id="g10" transform="matrix(1.25,0,0,-1.25,-1.4533166,-19.969848)">
      <g id="g12" transform="matrix(0.01033919,0.00922242,-0.00852155,0.01118954,4.886061,-32.307974)">
        <path
          d="M 76.9766,85.3633 1164.25,76.9766 1166.01,378.859 78.7383,387.25 76.9766,85.3633 Z M 1229.38,11.0781 c -6.98,-6.89451 -16.6,-11.1445063 -27.2,-11.07419375 L 38.1523,8.83984 C 16.957,8.95703 -0.125,26.2422 0,47.4336 L 2.21094,426.066 c 0.11719,21.188 17.41016,38.282 38.59376,38.149 L 1204.84,455.383 c 21.19,-0.121 38.28,-17.403 38.15,-38.602 l -2.21,-378.6326 c -0.06,-10.5976 -4.42,-20.1679 -11.4,-27.0703"
          fill="#fbfffe"
          style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          id="path14"
        />
        <path
          d="m 194.066,261.43 c -6.98,-6.907 -16.605,-11.153 -27.199,-11.086 -21.195,0.117 -38.277,17.402 -38.152,38.605 l 0.793,135.742 c 0.113,21.18 17.41,38.274 38.59,38.145 21.199,-0.125 38.285,-17.402 38.156,-38.606 l -0.789,-135.742 c -0.063,-10.586 -4.414,-20.164 -11.399,-27.058"
          fill="#fbfffe"
          style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          id="path16"
        />
        <path
          d="m 347.305,260.414 c -6.989,-6.906 -16.61,-11.144 -27.207,-11.086 -21.192,0.129 -38.274,17.402 -38.145,38.606 l 0.793,135.742 c 0.117,21.183 17.402,38.273 38.606,38.152 21.191,-0.125 38.269,-17.406 38.144,-38.601 l -0.797,-135.743 c -0.058,-10.597 -4.414,-20.168 -11.394,-27.07"
          fill="#fbfffe"
          style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          id="path18"
        />
        <path
          d="m 499.484,159.406 c -6.98,-6.902 -16.605,-11.14 -27.207,-11.082 -21.187,0.125 -38.269,17.403 -38.144,38.602 l 0.797,235.742 c 0.117,21.187 17.402,38.277 38.605,38.156 21.192,-0.129 38.27,-17.41 38.145,-38.605 l -0.797,-235.742 c -0.059,-10.594 -4.414,-20.164 -11.399,-27.071"
          fill="#fbfffe"
          style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          id="path20"
        />
        <path
          d="m 653.781,258.391 c -6.98,-6.903 -16.601,-11.141 -27.195,-11.082 -21.195,0.125 -38.277,17.402 -38.148,38.601 l 0.789,135.742 c 0.117,21.184 17.406,38.278 38.589,38.149 21.2,-0.121 38.282,-17.406 38.153,-38.598 L 665.18,285.461 c -0.059,-10.594 -4.414,-20.164 -11.399,-27.07"
          fill="#fbfffe"
          style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          id="path22"
        />
        <path
          d="m 806.398,257.668 c -6.98,-6.902 -16.597,-11.152 -27.207,-11.082 -21.187,0.125 -38.269,17.402 -38.14,38.602 l 0.797,135.742 c 0.117,21.187 17.406,38.277 38.605,38.148 21.192,-0.121 38.27,-17.402 38.145,-38.598 l -0.801,-135.75 c -0.059,-10.585 -4.414,-20.167 -11.399,-27.062"
          fill="#fbfffe"
          style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          id="path24"
        />
        <path
          d="m 1113.07,254.328 c -6.98,-6.894 -16.61,-11.14 -27.21,-11.082 -21.19,0.125 -38.27,17.41 -38.14,38.602 l 0.8,135.754 c 0.12,21.179 17.4,38.269 38.6,38.148 21.19,-0.129 38.27,-17.406 38.15,-38.609 l -0.8,-135.743 c -0.06,-10.593 -4.42,-20.164 -11.4,-27.07"
          fill="#fbfffe"
          style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          id="path26"
        />
        <path
          d="M 960.707,154.145 C 953.723,147.25 944.094,143 933.508,143.07 c -21.199,0.118 -38.281,17.403 -38.153,38.602 l 0.79,235.742 c 0.117,21.184 17.414,38.274 38.593,38.145 21.192,-0.121 38.282,-17.403 38.157,-38.602 l -0.793,-235.742 c -0.055,-10.586 -4.414,-20.168 -11.395,-27.07"
          fill="#fbfffe"
          style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          id="path28"
        />
      </g>
    </g>
  </svg>
);
export default MeasureIcon;
