import React, { useRef } from 'react';
import { MainLayout } from 'layout';
import { History } from './History';
import { Upload } from './Upload';
import { Row, Col } from 'antd';

export const DataPage = () => {
  const historyRef = useRef(null);

  return (
    <MainLayout>
      <Row gutter={16} style={{ padding: 32 }}>
        <Col span={8}>
          <Upload
            onUploadImg={() => {
              if (historyRef.current) {
                historyRef.current.refetch();
              }
            }}
          />
        </Col>
        <Col span={16}>
          <History ref={historyRef} />
        </Col>
      </Row>
    </MainLayout>
  );
};
