import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Alert, Button } from 'antd';
import { OnboardingLayout } from 'layout';
import { useState } from 'react';
import { signup, resendEmail } from 'api';
import { useMutation } from 'react-query';
import { MailOutlined } from '@ant-design/icons';
import { LoginForm, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';

export const SignupPage = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const { isLoading, mutate } = useMutation(() => resendEmail(email), {
    onSuccess: () => {},
    onError: (err) => {},
  });

  const handleSubmit = async (params) => {
    setError('');
    try {
      setEmail(params.email);
      await signup(params);
      setIsSuccess(true);
    } catch (error) {
      setError(error?.response?.data?.message || error.message);
    }
  };

  return (
    <OnboardingLayout>
      {isSuccess ? (
        <Card
          style={{
            width: 480,
            backgroundColor: 'rgba(10, 10, 10, 0.5)',
            backdropFilter: 'blur(4px)',
            height: 'fit-content',
          }}
        >
          <div title="Check your email">Check your email</div>
          <div>
            <p>
              A verification letter has been sent your email address. Please check your inbox and click
              verification link to finish registration process.
            </p>
            <Button onClick={mutate} mt={6} isLoading={isLoading}>
              Resend link
            </Button>
          </div>
        </Card>
      ) : (
        <LoginForm
          contentStyle={{
            minWidth: 360,
            maxWidth: '75vw',
            backgroundColor: 'rgba(10, 10, 10, 0.5)',
            backdropFilter: 'blur(4px)',
            color: '#eee!important',
            borderRadius: 6,
            boxShadow: '0px 0px 24px 0px rgba(0,0,0,0.1)',
            padding: '32px 32px 0 32px',
          }}
          onFinish={async (values) => {
            setEmail(values.email);
            await handleSubmit(values);
          }}
          actions={[
            <div style={{ textAlign: 'right' }}>
              <ProFormText key="loginLink">
                Have an account? &nbsp;
                <a href="/login" style={{ textDecoration: 'none' }}>
                  Login
                </a>
              </ProFormText>
              ,
            </div>,
          ]}
          submitter={{
            render: () => (
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  width: '100%',
                }}
              >
                Signup
              </Button>
            ),
          }}
        >
          <h1 style={{ textAlign: 'center' }}>Signup</h1>
          <ProFormText
            name="firstName"
            fieldProps={{
              size: 'large',
              prefix: <UserOutlined />,
            }}
            placeholder={'First Name'}
            rules={[
              {
                required: true,
                message: 'First name is required!',
              },
            ]}
          />
          <ProFormText
            name="lastName"
            fieldProps={{
              size: 'large',
              prefix: <UserOutlined />,
            }}
            placeholder={'Last Name'}
            rules={[
              {
                required: true,
                message: 'Last name is required!',
              },
            ]}
          />
          <ProFormText
            name="email"
            fieldProps={{
              size: 'large',
              prefix: <MailOutlined />,
            }}
            placeholder={'Email'}
            rules={[
              {
                required: true,
                message: 'Email is required!',
              },
              {
                type: 'email',
              },
            ]}
          />
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined />,
            }}
            placeholder={'Password'}
            rules={[
              {
                required: true,
                message: 'Password id required!',
              },
            ]}
          />
          <ProFormText.Password
            name="confirm-password"
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined />,
            }}
            placeholder={'Confirm Password'}
            rules={[
              {
                required: true,
                message: 'Confirm password is required!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The password that you entered do not match!'));
                },
              }),
            ]}
          />
          <ProFormCheckbox
            name="accept"
            rules={[
              {
                required: true,
                message: 'This is required!',
              },
            ]}
            style={{ paddingTop: 20 }}
          >
            I accept the{' '}
            <a href="/#" target="_blank" rel="noreferrer">
              terms of service
            </a>{' '}
            and{' '}
            <a href="/#" target="_blank" rel="noreferrer">
              privacy policy
            </a>
            .
          </ProFormCheckbox>

          {error && (
            <Alert
              style={{
                marginBottom: 24,
              }}
              message={error}
              type="error"
              showIcon
            />
          )}
        </LoginForm>
      )}
    </OnboardingLayout>
  );
};
