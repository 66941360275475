import { ProForm, ProFormText } from '@ant-design/pro-components';
import { Alert, Modal, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { inviteUser } from 'api';

export const NewUser = ({ open, onOpenChange, onNewUser = () => {} }) => {
  const formRef = useRef();
  const [error, setError] = useState('');
  useEffect(() => {
    if (!open) {
      formRef.current?.resetFields();
      setError('');
    }
    return () => {};
  }, [open]);

  return (
    <Modal
      title={'Invite new user'}
      width="400px"
      open={open}
      onCancel={async () => {
        onOpenChange(false);
      }}
      onOpenChange={(visible) => {
        formRef.current?.resetFields();
        onOpenChange(visible);
      }}
      footer={null}
    >
      <ProForm
        formRef={formRef}
        onFinish={async (values) => {
          setError('');
          const res = await inviteUser(values);
          if (res.status === 'error') {
            setError(res.message);
          } else {
            onNewUser();
            onOpenChange(false);
            message.success('Invited a new user successfully!');
          }
        }}
        submitter={{
          searchConfig: {
            submitText: 'Invite',
          },
          render: (_, doms) => {
            // For some reason, submit and reset buttons are reversed after
            // setting submitText.
            return doms.reverse();
          },
        }}
      >
        <ProFormText
          name="firstName"
          label={'First Name'}
          placeholder={'First Name'}
          rules={[
            {
              required: true,
            },
          ]}
        />
        <ProFormText
          name="lastName"
          label={'Last Name'}
          placeholder={'Last Name'}
          rules={[
            {
              required: true,
            },
          ]}
        />
        <ProFormText
          name="email"
          label={'Email'}
          placeholder={'Email'}
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Email is not valid',
            },
          ]}
        />

        {error && (
          <Alert
            style={{
              marginBottom: 24,
            }}
            message={error}
            type="error"
            showIcon
          />
        )}
      </ProForm>
    </Modal>
  );
};
