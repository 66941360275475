import React from 'react';
import { Card, Statistic } from 'antd';

export const Stats = ({ label, value = 0, isLoading, icon }) => {
  return (
    <Card>
      <Statistic title={label} value={value} prefix={icon} />
    </Card>
  );
};
