import { last } from 'lodash';
export const groupChats = (data) => {
  let result = [];
  let group = undefined;
  data.forEach((msg) => {
    if (!group) {
      group = {
        intent: msg.intent,
        messages: [msg],
      };
    } else {
      if (group.intent === msg.intent) {
        group.messages.push(msg);
      } else {
        let intent = group.intent;
        let lastMsg = last(group.messages);
        if (lastMsg && lastMsg.text === 'Created task successfully!' && lastMsg.sender === 'bot') {
          group.intent = 'create_task';
        } else {
          if (intent === 'create_task') {
            group.intent = 'exit_task';
          }
        }
        result.push(group);
        group = {
          intent: msg.intent,
          messages: [msg],
        };
      }
    }
  });
  if (group) {
    result.push(group);
  }
  return result;
};
