import React, { useRef } from 'react';
import './../style.scss';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip as Tooltip2,
  ResponsiveContainer,
  Pie,
  Cell,
  PieChart,
  Sector,
} from 'recharts';
import { toPng } from 'html-to-image';
import { Button, Card, Row, Col, Tooltip, Modal, Spin } from 'antd';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { getTaskReport } from 'api';
import { PieDraw } from './Pie';

export const ReportModal = ({ task, isReportOpen, setIsReportOpen }) => {
  const colors = ['#E74C3C ', '#9B59B6', '#2980B9', '#17A589', '#F1C40F', '#EB984E', '#BDC3C7', '#2C3E50'];
  const reportTemplateRef = useRef(null);
  const { data, isLoading } = useQuery(
    ['task_meta', task._id],
    () => getTaskReport(task.metaids, task.location, task.fromDate, task.toDate, task.collections),
    {
      enabled: !!task._id,
      onSuccess: (res) => {},
      onError: (error) => {},
    },
  );
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const handleGeneratePdf = () => {
    const options = {
      method: 'save',
      resolution: Resolution.HIGH,
      page: {
        margin: Margin.MEDIUM,
        format: 'A4',
        orientation: 'portrait',
      },
      filename: `${task.name}-report.pdf`,
    };
    generatePDF(reportTemplateRef, options);
  };
  const handleGeneratePNG = () => {
    toPng(reportTemplateRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${task.name}-report.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`PV ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };
  return (
    <Modal
      title={`${task.name} Report`}
      centered
      open={isReportOpen}
      onOk={() => setIsReportOpen(false)}
      onCancel={() => setIsReportOpen(false)}
      width={1000}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <Button onClick={() => handleGeneratePNG()}>Download as PNG</Button>
          <Button onClick={() => handleGeneratePdf()}>Download as PDF</Button>
          <CancelBtn />
          <OkBtn />
        </>
      )}
    >
      {isLoading ? (
        <div style={{ margin: '20px auto', textAlign: 'center' }}>
          <Spin tip="Loading" size="large"></Spin>
          <div style={{ marginTop: 12 }}>Loading...</div>
        </div>
      ) : (
        data &&
        data.data && (
          <div className="task-report-modal">
            <div style={{ background: '#fff', padding: 12 }} ref={reportTemplateRef}>
              <h3>{task.name} Report</h3>
              <div>Date: {format(new Date(), 'MM/dd/yyyy hh:mm:ss')}</div>
              <div>{data.data.description}</div>

              <h4>Summary</h4>
              {data.data.summary.map((item, i) => {
                if (item.type === 'text') {
                  return <div key={`${i}key`}>{item.text}</div>;
                } else if (item.type === 'img') {
                  return <img key={`${i}key`} src={item.img} alt="img" />;
                } else if (item.type === 'chart') {
                  const data = [];
                  item.x.forEach((x, index) => {
                    data.push({
                      x: x,
                      // y: parseInt(item.chart.y[index]),
                      ...item.y[index],
                    });
                  });
                  return (
                    <div key={`${i}key`} style={{ width: 'fit-content', margin: '20px auto' }}>
                      <div style={{ textAlign: 'center' }}>{item.title}</div>
                      <div>
                        <LineChart
                          width={500}
                          height={360}
                          data={data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 15,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="x" label={{ value: item.xlabel, position: 'center', dy: 20 }} />
                          <YAxis label={{ value: item.ylabel, position: 'center', angle: -90, dy: -20 }} />
                          <Tooltip2 />
                          {item.y_data_fields.map((field, idx) => (
                            <Line
                              key={`${idx}key`}
                              type="monotone"
                              dataKey={field}
                              name={field}
                              stroke={colors[idx % colors.length]}
                              activeDot={{ r: 8 }}
                            />
                          ))}
                        </LineChart>
                      </div>
                    </div>
                  );
                } else if (item.type === 'pie') {
                  let data = [];
                  item.y_data_fields.forEach((x, index) => {
                    data.push({
                      name: x,
                      value: item.data[x],
                      color: colors[index % colors.length],
                    });
                  });

                  return (
                    <div key={`${i}key`} style={{ width: 'fit-content', margin: '20px auto' }}>
                      <div style={{ textAlign: 'center' }}>{item.title}</div>
                      <PieDraw data={data} />
                    </div>
                  );
                } else if (item.type === 'table') {
                  return (
                    <div key={`${i}key`} style={{ width: 'fit-content', margin: '20px auto' }}>
                      <div>
                        <table>
                          <thead>
                            <tr>
                              {item.column_names.map((col, idx) => (
                                <th key={`${idx}key`}>{col}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {item.data.map((row, idx) => (
                              <tr key={idx}>
                                {row.map((itemVal, idx) => (
                                  <td key={`${idx}key`}>{itemVal}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )
      )}
    </Modal>
  );
};
