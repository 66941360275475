/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Tooltip, Card, Row, Col, Modal } from 'antd';
import { getDataHistory } from 'api';
import { FaRegTrashAlt } from 'react-icons/fa';
import { ProTable } from '@ant-design/pro-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { deleteDataById } from 'api';
import ImageZoom from 'react-image-zooom';
import { FiEdit3 } from 'react-icons/fi';
import { EditData } from './components/EditData';
import './style.scss';
const { confirm } = Modal;

export const History = forwardRef((props, ref) => {
  const actionRef = useRef();
  const [viewImgUrl, setViewImgUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const fetchData = async (params, sorter) => {
    let query = {
      offset: (params.current - 1) * params.pageSize,
      limit: params.pageSize,
    };
    if (Object.keys(sorter).length > 0) {
      query.sortBy = Object.keys(sorter)[0];
      query.order = Object.values(sorter)[0] === 'ascend' ? 'asc' : 'desc';
    }
    let res = await getDataHistory(query);

    return {
      success: true,
      data: res.result,
      total: res.total,
    };
  };

  const refetch = () => {
    actionRef.current.reload();
  };
  useImperativeHandle(ref, () => ({
    refetch,
  }));

  const handleRemove = (data) => {
    confirm({
      title: 'Delete image',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${data.name}?`,
      onOk: async () => {
        await deleteDataById(data._id);
        refetch();
      },
    });
  };
  const handleView = (url) => {
    setViewImgUrl(url);
    setIsModalOpen(true);
  };

  const handleEdit = (data) => {
    setSelectedData(data);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Resolution',
      dataIndex: 'resolution',
      sorter: true,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      sorter: true,
    },
    {
      title: 'Image',
      dataIndex: 'url',
      sorter: true,
      render: (_, record) => (
        <div>
          <Button onClick={() => handleView(`https://${record.url}`)}> View</Button>
        </div>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      sorter: true,
    },
    {
      title: 'Created At',
      dataIndex: 'date',
      sorter: true,
      // render: (_, record) => <>{format(new Date(record.createdAt), 'MM/dd/yyyy hh:mm:ss')}</>,
    },
    {
      title: 'Action',
      filters: false,
      hideInSearch: true,
      sorter: false,
      render: (_, record) => (
        <Row gutter={[12, 12]}>
          <Col>
            <Tooltip title="Edit">
              <Button icon={<FiEdit3 />} onClick={() => handleEdit(record)}></Button>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="Remove">
              <Button danger icon={<FaRegTrashAlt />} onClick={() => handleRemove(record)}></Button>
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Card style={{ flex: 1 }}>
      <ProTable
        headerTitle={'Upload History'}
        actionRef={actionRef}
        rowKey="_id"
        pagination={{
          pageSize: 10,
        }}
        search={false}
        request={fetchData}
        columns={columns}
        rowSelection={false}
        scroll={{ x: true }}
        options={{
          density: false,
        }}
      />
      <Modal
        title="View Image"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <ImageZoom
          className="view-image-modal-img"
          src={viewImgUrl}
          alt="view"
          zoom={300}
          width="100%"
          style={{ width: '100%', objectFit: 'contain' }}
        />
      </Modal>
      {selectedData && (
        <EditData
          data={selectedData}
          open={!!selectedData}
          onOpenChange={() => setSelectedData()}
          onEdit={() => {
            actionRef.current.reload();
          }}
        />
      )}
    </Card>
  );
});
