import { API } from 'utils/api';

export const inviteUser = async (payload) => {
  const response = await API().post('admin/invite', payload);
  return response.data;
};
export const getAdminMembers = async (params) => {
  const response = await API().get('admin/members', { params });
  return response.data.data;
};
export const getAdminStats = async () => {
  const response = await API().get('admin/stats');
  return response.data.data;
};
export const getAllMembers = async () => {
  const response = await API().get('admin/allMembers');
  return response.data.data.result;
};
export const deleteAdminMember = async (memberId) => {
  const response = await API().delete('admin/member/' + memberId);
  return response.data.data;
};
export const updateAdminMember = async (memberId, data) => {
  const response = await API().put('admin/member/' + memberId, data);
  return response.data;
};
