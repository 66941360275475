import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

export const AdminRoute = ({ children, ...rest }) => {
  const userInfo = localStorage.getItem('srs-user-state');
  let isAdmin = false;
  if (userInfo) {
    try {
      const user = JSON.parse(userInfo);
      isAdmin = user?.userInfo?.role === 'admin';
    } catch (error) {}
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem('jwt') && isAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
