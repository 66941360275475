import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import { Button, Card, Col, Modal, Row, Tooltip, Space, Tag } from 'antd';
import { deleteTaskById, getAllMembers, getTasks } from 'api';
import { useAuth } from 'contexts';
import { format } from 'date-fns';
import { MainLayout } from 'layout';
import { capitalize } from 'lodash';
import { useRef, useState } from 'react';
import { FaImages, FaRegTrashAlt } from 'react-icons/fa';
import { FiEdit3 } from 'react-icons/fi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { HiOutlineUsers } from 'react-icons/hi2';
import { useQuery } from 'react-query';
import { Stats } from './Stats';
import { EditTask, Gallery, NewTask, ReportModal } from './components';
import './style.scss';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
const { confirm } = Modal;

export const TaskPage = () => {
  const actionRef = useRef();
  const [newUserVisible, setNewUserVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState();
  const [total, setTotal] = useState(0);
  const { userInfo } = useAuth();
  const [memberData, setMemberData] = useState([]);
  const [openImages, setOpenImages] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);

  useQuery('adminMembers', () => getAllMembers(), {
    enabled: ['admin', 'superadmin'].includes(userInfo.role),
    onSuccess: (res) => {
      setMemberData([...res.map((a) => ({ ...a, id: a._id })), userInfo]);
    },
    onError: (error) => {},
  });
  const fetchData = async (params, sorter) => {
    let query = {
      offset: (params.current - 1) * params.pageSize,
      limit: params.pageSize,
    };
    if (Object.keys(sorter).length > 0) {
      query.sortBy = Object.keys(sorter)[0];
      query.order = Object.values(sorter)[0] === 'ascend' ? 'asc' : 'desc';
    }
    let res = await getTasks(query);
    setTotal(res.total);
    return {
      success: true,
      data: res.result,
      total: res.total,
    };
  };

  const handleRemoveTask = async (data) => {
    confirm({
      title: 'Delete task',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${data.name}?`,
      onOk: async () => {
        await deleteTaskById(data._id);
        actionRef.current.reload();
      },
    });
  };

  const handleShowImages = (data) => {
    setSelectedTask(data);
    setOpenImages(true);
  };

  const handleShowReport = (data) => {
    setSelectedTask(data);
    setIsReportOpen(true);
  };

  const handleEditTask = (data) => {
    setSelectedTask(data);
    setIsEditOpen(true);
  };

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      search: true,
      render: (_, record) => <a href={`/task?id=${record._id}`}>{record.name}</a>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
      search: true,
      valueType: 'select',
      valueEnum: {
        'ship detection': {
          text: 'Ship Detection',
        },
        'iceberg detection': {
          text: 'Iceberg Detection',
        },
        'airplane detection': {
          text: 'Airplane Detection',
        },
      },
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      // render: (_, record) => (
      //   <Tag color={record.type === 'ship detection' ? 'blue' : 'airplane detection' ? 'cyan' : 'purple'}>
      //     {record.type}
      //   </Tag>
      // ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      sorter: true,
      search: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      search: false,
      valueType: 'select',
      valueEnum: {
        'download failed': {
          text: 'Download Failed',
          status: 'Error',
        },
        completed: {
          text: 'Completed',
          status: 'Success',
        },
        pending: {
          text: 'Pending',
          status: 'Processing',
        },
        downloaded: {
          text: 'Downloaded',
          status: 'Processing',
        },
      },
      render: (item, record) => (
        <Tag
          icon={
            record.status === 'completed' ? (
              <CheckCircleOutlined />
            ) : record.status === 'downloaded' ? (
              <SyncOutlined spin />
            ) : record.status === 'pending' ? (
              <ClockCircleOutlined spin />
            ) : (
              <CloseCircleOutlined />
            )
          }
          color={
            record.status === 'completed'
              ? 'success'
              : record.status === 'downloaded'
              ? 'processing'
              : record.status === 'pending'
              ? 'default'
              : 'error'
          }
        >
          {capitalize(record.status === 'downloaded' ? 'processing' : record.status)}
        </Tag>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      sorter: true,
      search: false,
      render: (_, record) => <>{format(new Date(record.createdAt), 'MM/dd/yyyy hh:mm:ss')}</>,
    },
    {
      title: 'Action',
      filters: false,
      hideInSearch: true,
      sorter: false,
      search: false,
      render: (_, record) => (
        <Row gutter={[16, 16]}>
          <Col>
            <Tooltip>
              <Button icon={<FiEdit3 />} onClick={() => handleEditTask(record)}></Button>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip>
              <Button danger icon={<FaRegTrashAlt />} onClick={() => handleRemoveTask(record)}></Button>
            </Tooltip>
          </Col>
          {record.metaids && Array.isArray(record?.metaids) && record.metaids.length > 0 && (
            <Col>
              <Tooltip>
                <Button icon={<FaImages />} onClick={() => handleShowImages(record)}></Button>
              </Tooltip>
            </Col>
          )}
          {record?.status === 'completed' && (
            <Col>
              <Tooltip>
                <Button icon={<HiOutlineDocumentReport />} onClick={() => handleShowReport(record)}></Button>
              </Tooltip>
            </Col>
          )}
        </Row>
      ),
    },
  ];
  if (userInfo.role !== 'user') {
    columns = [
      ...columns.slice(0, 4),
      {
        title: 'Assigned User',
        dataIndex: 'userId',
        search: false,
        render: (_, record) => {
          let user = memberData.find((a) => a.id === record.userId);
          return user ? `${user.firstName} ${user.lastName}` : '';
        },
      },
      ...columns.slice(4, 6),
    ];
  }

  return (
    <MainLayout>
      <Row style={{ margin: 24 }} gutter={[16, 16]}>
        <Col span={6}>
          <Stats label="Total Tasks" value={total} icon={HiOutlineUsers} />
        </Col>
        {/* <Col span={6}>
          <Stats label="Total Users" isLoading={isStatsLoading} value={10} icon={LuUser} />
        </Col>
        <Col span={6}>
          <Stats label="Total Admins" isLoading={isStatsLoading} value={0} icon={TbUserCog} />
        </Col> */}
        <Col span={24}>
          <Card>
            <ProTable
              headerTitle={'Task Dashboard'}
              actionRef={actionRef}
              rowKey="_id"
              pagination={{
                pageSize: 10,
              }}
              // toolBarRender={() => [
              //   <Button type="primary" key="primary" onClick={() => setNewUserVisible(true)}>
              //     <PlusOutlined /> New Task
              //   </Button>,
              // ]}
              request={fetchData}
              columns={columns}
              rowSelection={false}
              scroll={{ x: true }}
              options={{
                density: false,
              }}
              search={false}
            />

            <NewTask
              open={newUserVisible}
              onOpenChange={setNewUserVisible}
              onNewUser={() => {
                actionRef.current.reload();
              }}
            />
            {selectedTask && isEditOpen && (
              <EditTask
                data={selectedTask}
                open={!!selectedTask}
                onOpenChange={() => {
                  setSelectedTask();
                  setIsEditOpen(false);
                }}
                onEdit={() => {
                  actionRef.current.reload();
                }}
              />
            )}
          </Card>
        </Col>
        {/* <Drawer title="Images" onClose={() => setOpenImages(false)} open={openImages}>
          {selectedTask &&
            selectedTask.analytics.length > 0 &&
            selectedTask.analytics[0].gallery.map((item, index) => (
              <Zoom key={`${index}key`}>
                <img src={item.img} width={300} height={150} alt="message" />
              </Zoom>
            ))}
        </Drawer> */}
        <Modal
          title="Images"
          centered
          open={openImages}
          onOk={() => setOpenImages(false)}
          onCancel={() => setOpenImages(false)}
          width={1400}
        >
          <Gallery task={selectedTask} />
        </Modal>
        {selectedTask && isReportOpen && (
          <ReportModal
            isReportOpen={isReportOpen}
            setIsReportOpen={(e) => setIsReportOpen(e)}
            task={selectedTask}
          />
        )}
      </Row>
    </MainLayout>
  );
};
