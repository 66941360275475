import axios from 'axios';
import { API } from 'utils/api';

export const addChat = async (payload) => {
  const response = await API().post('chat/add', payload);
  return response.data;
};

export const getChatLog = async (payload) => {
  const response = await API().get('chat/log');
  return response.data;
};

export const getMessage = async (data) => {
  const res = await axios.post(`${process.env.REACT_APP_LANGCHAIN_REST_URL}/chat`, data);
  return res.data;
};
