import { API } from 'utils/api';

export const uploadImg = async (payload) => {
  const response = await API().post('data/upload', payload);
  return response.data;
};

export const getDataHistory = async (params) => {
  const response = await API().get('data/log', { params });
  return response.data.data;
};
export const deleteDataById = async (id) => {
  const response = await API().delete('data/' + id);
  return response.data;
};
export const updateDataById = async (id, data) => {
  const response = await API().put('data/' + id, data);
  return response.data;
};
