import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { LoginForm, ProConfigProvider, ProFormText } from '@ant-design/pro-components';
import { theme, Alert, Button } from 'antd';
import { login } from 'api';
import { useAuth } from 'contexts';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { OnboardingLayout } from 'layout';

const Page = () => {
  const { token } = theme.useToken();
  const { updateUserInfo } = useAuth();
  let history = useHistory();
  const [error, setError] = useState('');

  return (
    <OnboardingLayout>
      <LoginForm
        contentStyle={{
          minWidth: 360,
          maxWidth: '75vw',
          backgroundColor: 'rgba(10, 10, 10, 0.5)',
          backdropFilter: 'blur(4px)',
          color: '#eee!important',
          borderRadius: 6,
          boxShadow: '0px 0px 24px 0px rgba(0,0,0,0.1)',
          padding: '32px',
        }}
        onFinish={async (params) => {
          localStorage.clear();
          setError('');
          try {
            const res = await login(params);
            if (res.message === 'OK') {
              updateUserInfo(res.user);
              localStorage.setItem('jwt', res.token);
              history.push('/dashboard');
            }
          } catch (error) {
            setError(error?.response?.data?.message || error.message);
          }
        }}
        actions={[
          <div key="signupLink" style={{ paddingTop: 12, fontSize: 14, color: '#eee', textAlign: 'right' }}>
            {'Not have an account?'}{' '}
            <a href="/signup" style={{ textDecoration: 'none', color: '#1668dc' }}>
              Sign up
            </a>
          </div>,
        ]}
        submitter={{
          render: () => (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                width: '100%',
              }}
            >
              Login
            </Button>
          ),
        }}
      >
        <>
          <h1 style={{ textAlign: 'center' }}>Login</h1>
          <ProFormText
            name="email"
            fieldProps={{
              size: 'large',
              prefix: (
                <UserOutlined
                  style={{
                    color: token.colorText,
                  }}
                  className={'prefixIcon'}
                />
              ),
            }}
            placeholder={'Email'}
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Email is required!',
              },
            ]}
          />
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: 'large',
              prefix: (
                <LockOutlined
                  style={{
                    color: token.colorText,
                  }}
                  className={'prefixIcon'}
                />
              ),
            }}
            placeholder={'Password'}
            rules={[
              {
                required: true,
                message: 'Password is required！',
              },
            ]}
          />
        </>

        <div
          style={{
            marginBlockEnd: 24,
            paddingBottom: 8,
          }}
        >
          <a
            style={{
              float: 'right',
            }}
            href="/forgotPassword"
          >
            Forgot password?
          </a>
        </div>
        {error && (
          <Alert
            style={{
              marginBottom: 12,
            }}
            message={error}
            type="error"
            showIcon
          />
        )}
      </LoginForm>
    </OnboardingLayout>
  );
};
export const LoginPage = () => {
  return (
    <ProConfigProvider dark>
      <Page />
    </ProConfigProvider>
  );
};
