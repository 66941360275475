/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadImg } from 'api';
import { useMutation } from 'react-query';
import { Card, Button, Input, message, DatePicker } from 'antd';
import dayjs from 'dayjs';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};
const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #bbb',
  width: 200,
  height: 200,
  padding: 4,
  boxSizing: 'border-box',
  objectFit: 'contain',
};

const thumbImg = {
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'contain',
};
export const Upload = ({ onUploadImg = () => {} }) => {
  const [name, setName] = useState('');
  const [resolution, setResolution] = useState('');
  const [location, setLocation] = useState('');
  const [files, setFiles] = useState([]);
  const [date, setDate] = useState(new Date());
  const [messageApi, contextHolder] = message.useMessage();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <img
        src={file.preview}
        style={thumbImg}
        // Revoke data uri after image is loaded
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
        alt="preview"
      />
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);
  const { isLoading, mutate } = useMutation((data) => uploadImg(data), {
    onSuccess: () => {
      messageApi.open({
        type: 'success',
        content: 'Uploaded the image successfully!',
      });
      setName('');
      setResolution('');
      setLocation('');
      setFiles([]);
      onUploadImg();
      setDate(new Date());
    },
    onError: (error) => {
      messageApi.open({
        type: 'error',
        content: error?.response?.data?.message || error.message,
      });
    },
  });
  const handleSubmit = async () => {
    if (!name) {
      messageApi.open({
        type: 'error',
        content: 'Please input name!',
      });
    } else if (!resolution) {
      messageApi.open({
        type: 'error',
        content: 'Please input resolution!',
      });
    } else if (!location) {
      messageApi.open({
        type: 'error',
        content: 'Please input location!',
      });
    } else if (files.length === 0) {
      messageApi.open({
        type: 'error',
        content: 'Please upload image!',
      });
    } else if (!date) {
      messageApi.open({
        type: 'error',
        content: 'Please input date!',
      });
    } else {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('resolution', resolution);
      formData.append('location', location);
      formData.append('date', dayjs(date).format('YYYY-MM-DD HH:mm:ss'));
      formData.append('image', files[0]);
      mutate(formData);
    }
  };

  return (
    <Card title="Data Upload">
      {contextHolder}
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <div style={{ display: 'flex' }}>
          <label style={{ margin: 'auto 0', width: 80 }}>Name:</label>
          <Input
            style={{ flex: 1 }}
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <label style={{ margin: 'auto 0', width: 80 }}>Resolution:</label>
          <Input
            style={{ flex: 1 }}
            placeholder="Resolution"
            value={resolution}
            onChange={(e) => setResolution(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <label style={{ margin: 'auto 0', width: 80 }}>Location:</label>
          <Input
            style={{ flex: 1 }}
            placeholder="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <label style={{ margin: 'auto 0', width: 80 }}>Date:</label>
          <DatePicker
            showTime
            defaultValue={dayjs(date)}
            value={dayjs(date)}
            onChange={(e) => setDate(e)}
            onOk={(e) => setDate(e)}
            style={{ flex: 1 }}
          />
        </div>
        <section className="container" style={{ marginTop: 20 }}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {files.length > 0 ? (
              <aside style={thumbsContainer}>{thumbs}</aside>
            ) : (
              <p>Drag 'n' drop an image here, or click to select files</p>
            )}
          </div>
        </section>
        <Button
          type="primary"
          style={{ marginTop: 20, marginLeft: 'auto' }}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Card>
  );
};
