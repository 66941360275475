import React, { useRef, useState } from 'react';
import { MainLayout } from 'layout';
import {
  Circle,
  FeatureGroup,
  LayerGroup,
  LayersControl,
  Map,
  Marker,
  Popup,
  Rectangle,
  TileLayer,
  SVGOverlay,
  Polyline,
  Polygon,
} from 'react-leaflet';
import './style.scss';
import L from 'leaflet';
import { uniq } from 'lodash';
import { useQuery } from 'react-query';
import { getAllTrajectories } from 'api';

const { BaseLayer, Overlay } = LayersControl;
const center = [51.505, -0.09];
const vesselIcon = (angle, type, status = 'running') => {
  let color = 'blue';
  switch (type) {
    case 'GENERAL_CARGO':
      color = 'red';
      break;
    case 'GENERAL_TANKER':
      color = 'green';
      break;
    case 'PASSENGER':
      color = 'blue';
      break;
    case 'SPECIAL_CRAFT':
      color = 'yellow';
      break;
    case 'MEDICAL_TRANS':
      color = 'yellow';
      break;
    case 'LAW_ENFORCEMENT':
      color = 'yellow';
      break;
    case 'ANTI_POLLUTION':
      color = 'yellow';
      break;
    case 'PORT_TENDER':
      color = 'yellow';
      break;
    case 'TUG':
      color = 'yellow';
      break;
    case 'SEARCH_AND_RESCUE':
      color = 'yellow';
      break;
    case 'PILOT_VESSEL':
      color = 'yellow';
      break;
    case 'HIGH_SPEED_CRAFT':
      color = 'yellow';
      break;
    case 'PLEASURE_CRAFT':
      color = 'yellow';
      break;
    case 'FISHING':
      color = 'yellow';
      break;
    case 'DIVE_VESSEL':
      color = 'yellow';
      break;
    case 'MILITARY_OPS':
      color = 'yellow';
      break;
    case 'SAILING':
      color = 'yellow';
      break;
    default:
      color = 'blue';
  }
  return new L.divIcon({
    className: 'custom-arrow-marker',
    html:
      status === 'running'
        ? `<div class="arrow" style="transform: rotate(${angle}deg); border-top-color: ${color};"></div>`
        : `<div class="circle" style="background-color: ${color};"></div>`,
    iconSize: [20, 20], // Adjust this size as needed
    iconAnchor: [10, 10], // Adjust this as needed
  });
};
function calculateAngle(coordinates) {
  if (coordinates.length < 2) return 0;
  // Extract the coordinates
  const [x1, y1] = coordinates[coordinates.length - 2];
  const [x2, y2] = coordinates[coordinates.length - 1];

  // Calculate the differences
  const deltaX = x1 - x2;
  const deltaY = y1 - y2;

  // Calculate the angle in radians
  const angleRadians = Math.atan2(deltaY, deltaX);

  // Convert the angle to degrees
  const angleDegrees = angleRadians * (180 / Math.PI);

  return angleDegrees;
}
export const MarineWatchPage = () => {
  const mapRef = useRef();
  const [trajectoryData, setTrajectoryData] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState(null);

  const handleFilter = async (res) => {
    setTrajectoryData(
      res
        .filter((item) => item['Latitude'] && item['Longitude'])
        .map((item) => {
          let route = [];
          if (item.coordinates.length > 0) {
            route = uniq([
              ...item.coordinates[0].coordinates
                .filter((item) => item['Latitude'] && item['Longitude'])
                .map((item) => [item['Latitude'], item['Longitude']]),
              [item['Latitude'], item['Longitude']],
            ]);
          }

          return {
            ...item,
            position: [item['Latitude'], item['Longitude']],
            route: route,
            angle: calculateAngle(route),
            status: route.length > 0 ? 'running' : 'stopped',
          };
        }),
    );
    setSelectedVessel(null);
  };

  return (
    <MainLayout titleText={'Marine Watch'} onFilter={handleFilter}>
      <div className="marine-watch-wrapper">
        <Map center={center} zoom={3}>
          <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
          {trajectoryData.map((item, index) => (
            <Marker
              position={item.position}
              icon={vesselIcon(item.angle, item.Type, item.status)}
              key={index}
              onClick={() => {
                setSelectedVessel(item);
                console.log('select', item);
              }}
            >
              <Popup>
                Name: {item.ShipName} <br />
                MMSI: {item.MMSI} <br />
                Type: {item.Type}
              </Popup>
            </Marker>
          ))}
          {selectedVessel && selectedVessel.route.length > 0 && (
            <Polyline positions={selectedVessel.route} color="purple" weight={1} className="dash-dot-line" />
          )}
        </Map>
      </div>
    </MainLayout>
  );
};
