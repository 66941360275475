import React, { useState, useMemo } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import { FaTasks } from 'react-icons/fa';
import { GoHome, GoStack, GoGear } from 'react-icons/go/index.js';
import { LiaUserCogSolid } from 'react-icons/lia';
import { Layout, Menu, Button, Avatar, Dropdown, Select } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'contexts';
import { RiShipLine } from 'react-icons/ri/index.js';
import { CONTINENT_DATA, COUNTRY_DATA, MARINE_TIME_RANGE, MID_COUNTRY_DATA } from 'config';
import './style.scss';
import { getAllTrajectories } from 'api';

const { Header, Sider, Content } = Layout;

export const MainLayout = ({ children, titleText = '', onFilter = () => {} }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false);
  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedTimeRange, setSelectedTimeRange] = useState(MARINE_TIME_RANGE[0]);
  const history = useHistory();
  let location = useLocation();
  const { userInfo } = useAuth();
  const countryData = useMemo(() => {
    return COUNTRY_DATA.filter((c) => c.continent === selectedContinent).map((c) => ({
      label: c.country,
      value: c.country,
    }));
  }, [selectedContinent]);
  const menuItems = useMemo(() => {
    let result = [
      {
        key: '/marine-watch',
        icon: <RiShipLine />,
        label: 'Marine Watch',
      },
      {
        key: '/dashboard',
        icon: <GoHome />,
        label: 'Dashboard',
      },
      {
        key: '/data',
        icon: <GoStack />,
        label: 'Data Panel',
      },
      {
        key: '/settings',
        icon: <GoGear />,
        label: 'Settings',
      },
      {
        key: '/tasks',
        icon: <FaTasks />,
        label: 'Tasks',
      },
    ];
    if (userInfo.role === 'admin') {
      result = [
        ...result,
        {
          key: '/admin',
          icon: <LiaUserCogSolid />,
          label: 'Admin',
        },
      ];
    }
    return result;
  }, [userInfo.role]);

  const title = useMemo(() => {
    const item = menuItems.find((item) => item.key === location.pathname);
    return item ? item.label : '';
  }, [location.pathname]);

  const userMenu = (
    <Menu>
      <Menu.Item key="name">{`${userInfo.firstName} ${userInfo.lastName}`}</Menu.Item>
      <Menu.Item
        key="profile"
        icon={<UserOutlined />}
        onClick={() => {
          history.push('/profile');
        }}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        key="change_password"
        icon={<KeyOutlined />}
        onClick={() => {
          history.push('/changePassword');
        }}
      >
        Change Password
      </Menu.Item>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        onClick={() => {
          localStorage.clear();
          history.push('/login');
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const handleContinentChange = (value) => {
    setSelectedContinent(value);
    setSelectedCountry('');
  };
  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };
  const handleChangeTimeRange = (value) => {
    setSelectedTimeRange(value);
  };

  const handleFilter = async () => {
    let countries = [];
    if (selectedCountry) {
      countries.push(selectedCountry);
    } else if (selectedContinent) {
      countries = COUNTRY_DATA.filter((c) => c.continent === selectedContinent).map((c) => c.country);
    } else {
      return;
    }
    let mids = [];
    for (const key in MID_COUNTRY_DATA) {
      if (countries.includes(MID_COUNTRY_DATA[key])) {
        mids.push(key);
      }
    }
    setIsFiltering(true);
    const res = await getAllTrajectories({ mids, timeRange: selectedTimeRange.value });

    onFilter(res);
    setIsFiltering(false);
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider
        theme="light"
        trigger={null}
        style={{ borderRight: '1px solid #ddd' }}
        collapsible
        collapsed={collapsed}
      >
        <div style={{ display: 'flex', height: 60 }}>
          <img
            src={collapsed ? '/min-logo.png' : '/logo.png'}
            alt="logo"
            style={{
              width: collapsed ? 48 : 160,
              height: collapsed ? 48 : 22,
              margin: 'auto',
              objectFit: 'contain',
            }}
          />
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={['/dashboard']}
          onClick={(e) => {
            history.push(e.key);
            localStorage.removeItem('cdsebrowser_oauth');
            localStorage.removeItem('copernicus_browser_lang');
          }}
          selectedKeys={[location.pathname]}
          items={menuItems}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            display: 'flex',
            background: 'linear-gradient(45deg, rgb(250 250 250), rgb(207 223 245))',
            borderBottom: '1px solid #ddd',
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          {/* {title || titleText} */}
          {location.pathname === '/marine-watch' && (
            <div className="marine-layout">
              <div>Continent:</div>
              <Select
                defaultValue=""
                style={{ width: 140 }}
                onChange={handleContinentChange}
                value={selectedContinent}
                options={CONTINENT_DATA.map((c) => ({ value: c, label: c }))}
              />
              <div>Country:</div>
              <Select
                defaultValue=""
                style={{ width: 160 }}
                onChange={handleCountryChange}
                value={selectedCountry}
                options={countryData}
              />
              <div>Time Range:</div>
              <Select
                defaultValue=""
                style={{ width: 140 }}
                onChange={handleChangeTimeRange}
                value={selectedTimeRange}
                options={MARINE_TIME_RANGE}
              />
              <Button type="primary" onClick={handleFilter} loading={isFiltering}>
                Filter
              </Button>
            </div>
          )}
          <div style={{ margin: 'auto 24px auto auto' }}>
            <Dropdown overlay={userMenu} trigger={['click']}>
              <Avatar style={{ backgroundColor: '#577ba0' }} icon={<UserOutlined />} />
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            minHeight: 280,
            background: '#f6faff',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
