import React, { useRef } from 'react';
import { MainLayout } from 'layout';
import { ProForm, ProFormText } from '@ant-design/pro-components';
import { Card, message, Button } from 'antd';
import { changePassword } from 'api';
import { UserOutlined } from '@ant-design/icons';

export const ChangePasswordPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const formRef = useRef();

  const handleSubmit = async (params) => {
    try {
      const res = await changePassword({ ...params });

      messageApi.open({
        type: res.status,
        content: res.message,
      });
      if (res.status === 'success') {
        formRef.current.reset();
      }
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error?.response?.data?.message || error.message,
      });
    }
  };
  return (
    <MainLayout>
      {contextHolder}
      <Card
        style={{ maxWidth: 440, margin: '100px auto auto auto' }}
        display={'flex'}
        justifyContent={'center'}
      >
        <ProForm
          layout={'horizontal'}
          grid={true}
          rowProps={{
            gutter: [16, 0],
          }}
          initialValues={{
            oldPassowrd: '',
            newPassword: '',
            confirmPassword: '',
          }}
          onFinish={async (values) => {
            await handleSubmit(values);
          }}
          submitter={{
            submitButtonProps: {
              style: {
                width: '100%',
              },
            },
            render: () => (
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  width: '100%',
                }}
              >
                Save Changes
              </Button>
            ),
          }}
        >
          <h1 style={{ margin: '0 auto 20px auto' }}>Change Password</h1>
          <ProFormText.Password
            name="oldPassword"
            fieldProps={{
              size: 'large',
              prefix: <UserOutlined />,
            }}
            placeholder={'Old Password'}
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Old Password is required!',
              },
            ]}
          />
          <ProFormText.Password
            name="newPassword"
            fieldProps={{
              size: 'large',
              prefix: <UserOutlined />,
            }}
            placeholder={'Password'}
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Password is required!',
              },
            ]}
          />
          <ProFormText.Password
            name="confirmPassword"
            fieldProps={{
              size: 'large',
              prefix: <UserOutlined />,
            }}
            placeholder={'Confirm Password'}
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Confirm Password is required!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The passwords that you entered do not match!'));
                },
              }),
            ]}
          />
        </ProForm>
      </Card>
    </MainLayout>
  );
};
