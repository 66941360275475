import geo_area from '@mapbox/geojson-area';
import { createTask, getAllTasks, validateWithDates } from 'api';
import { cloneDeep, uniq } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { t } from 'ttag';
import { CustomDropdownIndicator } from '../../components/CustomSelectInput/CustomDropdownIndicator';
import { customSelectStyle } from '../../components/CustomSelectInput/CustomSelectStyle';
import { TimespanPicker } from '../../components/TimespanPicker/TimespanPicker';
import { DEFAULT_MODE, DEFAULT_THEME_ID, MODE_THEMES_LIST, TABS } from '../../const';
import { ReactComponent as ChevronDown } from '../../icons/chevron-down.svg';
import { ReactComponent as MagnifierSvg } from '../../icons/magnifier.svg';
import { EOBButton } from '../../junk/EOBCommon/EOBButton/EOBButton';
import store, {
  mainMapSlice,
  searchResultsSlice,
  tabsSlice,
  themesSlice,
  visualizationSlice,
} from '../../store';
import { getBoundsAndLatLng } from '../CommercialDataPanel/commercialData.utils';
import { getDataSourceHandler } from '../SearchPanel/dataSourceHandlers/dataSourceHandlers';
import CollectionForm, {
  getCollectionFormInitialState,
} from './../VisualizationPanel/CollectionSelection/AdvancedSearch/CollectionForm';
import { isDatasetIdGIBS } from './../VisualizationPanel/SmartPanel/LatestDataAction.utils';
import { taskCollections } from '../VisualizationPanel/CollectionSelection/AdvancedSearch/collectionFormConfig';

const ErrorCode = {
  noProductsFound: 'noProductsFound',
  selectSearchCriteria: 'selectSearchCriteria',
  invalidTimeRange: 'invalidTimeRange',
  invalidDateRange: 'invalidDateRange',
};
const ErrorMessage = {
  [ErrorCode.noProductsFound]: () => t`No products were found for the selected search parameters.\n
  To get more results, try selecting more data sources, extending the time range and/or selecting a larger area on the map.`,
  [ErrorCode.selectSearchCriteria]: () => t`Please select at least one search criteria!`,
  [ErrorCode.invalidTimeRange]: () => t`Invalid time range!`,
  [ErrorCode.invalidDateRange]: () => t`Invalid date range!`,
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <CustomDropdownIndicator {...props} magnifier={MagnifierSvg} chevronDown={ChevronDown} />
      </components.DropdownIndicator>
    )
  );
};
const TaskTypes = [
  {
    label: 'Ship Detection',
    value: 'ship detection',
  },
  {
    label: 'Iceberg Detection',
    value: 'iceberg detection',
  },
  {
    label: 'Airplane Detection',
    value: 'airplane detection',
  },
];
const generateTaskName = () => {
  // generate task name based on current time
  const date = new Date();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const mili = date.getMilliseconds();
  const userState = localStorage.getItem('srs-user-state');
  const firstName = userState ? JSON.parse(userState).userInfo.firstName : 'guest';
  const lastName = userState ? JSON.parse(userState).userInfo.lastName : 'guest';
  return `${firstName}_${lastName}_${seconds}${month}${minutes}${day}${hours}${mili}`;
};
class TaskPanel extends Component {
  state = {
    taskName: generateTaskName(),
    taskType: 'ship detection',
    taskError: '',
    isTaskCreating: false,
    fromMoment: moment.utc().subtract(1, 'month').startOf('day'),
    toMoment: moment.utc().endOf('day'),
    datepickerIsExpanded: false,
    filterMonths: null,
    displayCalendarFrom: false,
    displayCalendarTo: false,
    collectionForm: getCollectionFormInitialState(),
    searchCriteria: '',
    formValidationError: '',
    additionFiltersPositionTop: 0,
  };

  calendarHolder = React.createRef();
  container;

  handleCreateTask = async () => {
    this.setState({ taskError: '', isTaskCreating: true });
    if (!this.state.taskName) {
      this.setState({ taskError: 'Please enter a task name!', isTaskCreating: false });
      return;
    } else if (!this.props.aoiGeometry) {
      this.setState({ taskError: 'Please select an area!', isTaskCreating: false });
      return;
    } else {
      const area = (parseFloat(geo_area.geometry(this.props.aoiGeometry)) / 1000000).toFixed(2);
      if (area >= 100) {
        this.setState({
          isTaskCreating: false,
          taskError: 'Please select an area which is smaller than 100km!',
        });
        return;
      }
      const validateRes = await validateWithDates(
        this.state.fromMoment.format('yyyy-MM-DD'),
        this.state.toMoment.format('yyyy-MM-DD'),
        0,
        [...this.props.aoiGeometry.coordinates[0][0], ...this.props.aoiGeometry.coordinates[0][2]].join(','),
      );
      if (!validateRes.result) {
        this.setState({
          isTaskCreating: false,
          taskError: 'Your configuration is invalid. Please change the selected region and date range.',
        });
        return;
      }
      this.setState({ isTaskCreating: true });
      const qp = queryString.parse(window.location.search);
      const { selectedCollections } = this.state.collectionForm;

      await createTask({
        name: this.state.taskName,
        type: this.state.taskType,
        lat: qp.lat,
        lng: qp.lng,
        zoom: qp.zoom,
        datasetId: qp.datasetId,
        themeId: qp.themeId,
        visualizationUrl: qp.visualizationUrl,
        bbox: this.props.aoiGeometry,
        collections: selectedCollections,
        fromDate: this.state.fromMoment.format('yyyy-MM-DD hh:mm'),
        toDate: this.state.toMoment.format('yyyy-MM-DD hh:mm'),
      });
      toast.success('Created Task Successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      getAllTasks().then((tasks) => {
        store.dispatch(mainMapSlice.actions.setTasks(tasks));
      });
      this.setState({ isTaskCreating: false, taskError: '' });
    }
  };

  componentWillUnmount() {
    store.dispatch(searchResultsSlice.actions.reset());
  }

  componentDidMount() {
    this.setSelectedCollections({
      Maxar: {
        ge01: true,
        wv01: true,
        wv02: true,
        'wv03-vnir': true,
        'wv03-swir': true,
        wv04: true,
      },
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isExpanded && !this.props.isExpanded) {
      this.shouldDisplayTileGeometries(false);
    }
    if (!prevProps.isExpanded && this.props.isExpanded) {
      this.shouldDisplayTileGeometries(true);
    }

    if (this.props.oDataSearchResult !== prevProps?.oDataSearchResult) {
      store.dispatch(searchResultsSlice.actions.setSearchResult(this.props.oDataSearchResult));
      store.dispatch(
        searchResultsSlice.actions.setSearchFormData({
          fromMoment: this.state.fromMoment,
          toMoment: this.state.toMoment,
          collectionForm: this.state.collectionForm,
          searchCriteria: this.state.searchCriteria,
        }),
      );
    }
    //populate search form with params used for last search when go to search is selected
    if (this.props.searchFormData && !this.props.resultsPanelSelected) {
      this.setState((state) => ({
        ...state,
        fromMoment: this.props.searchFormData.fromMoment,
        toMoment: this.props.searchFormData.toMoment,
        collectionForm: this.props.searchFormData.collectionForm,
        searchCriteria: this.props.searchFormData.searchCriteria || '',
      }));
      //reset last search params
      store.dispatch(searchResultsSlice.actions.setSearchFormData(null));
    }
    if (this.props.aoiGeometry && this.state.taskError === 'Please select an area!') {
      this.setState({ taskError: '' });
    }
  }

  shouldDisplayTileGeometries = (shouldDisplay) => {
    store.dispatch(searchResultsSlice.actions.setDisplayingSearchResults(shouldDisplay));
  };

  setAdditionalFiltersPositionTop = (value) => this.setState({ additionFiltersPositionTop: value });

  resetSearch = () => {
    store.dispatch(searchResultsSlice.actions.reset());
  };

  onResultSelected = (tile) => {
    const fromTime = moment(tile.sensingTime).utc().startOf('day');
    const toTime = moment(tile.sensingTime).utc().endOf('day');

    if (
      !(
        this.props.selectedThemesListId === MODE_THEMES_LIST &&
        this.props.selectedThemeId === DEFAULT_THEME_ID
      )
    ) {
      store.dispatch(
        themesSlice.actions.setSelectedThemeId({
          selectedThemeId: DEFAULT_MODE.themes[0].id,
          selectedThemesListId: MODE_THEMES_LIST,
        }),
      );
    }

    if (tile.datasetId !== this.props.datasetId) {
      store.dispatch(visualizationSlice.actions.setNewDatasetId({ datasetId: tile.datasetId }));
    }
    store.dispatch(
      visualizationSlice.actions.setVisualizationParams({
        fromTime: isDatasetIdGIBS(tile.datasetId) ? null : fromTime,
        toTime: toTime,
      }),
    );
    store.dispatch(searchResultsSlice.actions.setDisplayingSearchResults(false));
    store.dispatch(tabsSlice.actions.setTabIndex(TABS.VISUALIZE_TAB));

    if (!this.props.showLayerPanel && this.props.setShowLayerPanel) {
      this.props.setShowLayerPanel(true);
    }

    if (tile.geometry) {
      const { lat, lng, zoom } = getBoundsAndLatLng(tile.geometry);
      const dsh = getDataSourceHandler(tile.datasetId);
      const { min: minZoom, max: maxZoom } = (dsh && dsh.getLeafletZoomConfig(tile.datasetId)) || {};

      /*use best(highest) possible zoom calculated from 
      - current map zoom (this.props.zoom), 
      - min zoom for selected layer(minZoom) 
      - zoom calculated from bounds(zoom)      
      */
      let newZoom = Math.max(minZoom, zoom, this.props.zoom);

      if (newZoom > maxZoom) {
        newZoom = maxZoom;
      }

      store.dispatch(
        mainMapSlice.actions.setPosition({
          lat: lat,
          lng: lng,
          zoom: newZoom,
        }),
      );
    }
    this.props.setCollectionSelectionExpanded && this.props.setCollectionSelectionExpanded(false);
  };

  setHighlightedTile = (tile) => {
    store.dispatch(searchResultsSlice.actions.setHighlightedTile(tile));
  };

  handleDatepickerExpanded = (expanded) => {
    this.setState({
      datepickerIsExpanded: expanded,
    });
  };

  setSelectedCollections = (selectedCollections) => {
    this.setState((state) => {
      const { maxCc, selectedFilters } = state.collectionForm;
      const newSelectedFilters = cloneDeep(selectedFilters) ?? {};

      //remove filters for unselected collections
      const filtersForUnselectedCollections = Object.keys(selectedFilters).filter(
        (collectionFilterKey) => !Object.keys(selectedCollections).find((key) => key === collectionFilterKey),
      );

      filtersForUnselectedCollections.forEach((c) => delete newSelectedFilters?.[c]);

      const newCollectionFormState = {
        selectedCollections: selectedCollections,
        maxCc: maxCc,
        selectedFilters: newSelectedFilters,
      };
      return {
        collectionForm: newCollectionFormState,
      };
    });
  };

  setMaxCc = (maxCc) => {
    this.setState((state) => {
      const { selectedCollections, selectedFilters } = state.collectionForm;
      const newCollectionFormState = {
        selectedCollections: selectedCollections,
        maxCc: maxCc,
        selectedFilters: selectedFilters,
      };
      return {
        collectionForm: newCollectionFormState,
      };
    });
  };

  setSelectedFilters = (collectionId, filterId, value) => {
    this.setState((state) => {
      const { selectedCollections, maxCc, selectedFilters } = state.collectionForm;
      const newSelectedFilters = cloneDeep(selectedFilters);

      if (value) {
        newSelectedFilters[collectionId] = {
          ...newSelectedFilters?.[collectionId],
          [filterId]: value,
        };
      } else {
        delete newSelectedFilters?.[collectionId]?.[filterId];
      }

      const newCollectionFormState = {
        selectedCollections: selectedCollections,
        maxCc: maxCc,
        selectedFilters: newSelectedFilters,
      };

      return {
        collectionForm: newCollectionFormState,
      };
    });
  };

  resetSelectedFilters = (collectionId) => {
    this.setState((state) => {
      const { selectedCollections, maxCc, selectedFilters } = state.collectionForm;
      const newSelectedFilters = cloneDeep(selectedFilters);
      delete newSelectedFilters?.[collectionId];

      const newCollectionFormState = {
        selectedCollections: selectedCollections,
        maxCc: maxCc,
        selectedFilters: newSelectedFilters,
      };
      return {
        collectionForm: newCollectionFormState,
      };
    });
  };

  setFilterMonths = (filterMonths) => {
    this.setState({
      filterMonths: filterMonths,
    });
  };

  getAndSetNextPrevDateFrom = async (direction, selectedDay, toMoment, minDate) => {
    let newFromMoment;
    if (direction === 'prev') {
      newFromMoment = moment.utc(selectedDay).add(-1, 'days');
    } else {
      newFromMoment = moment.utc(selectedDay).add(1, 'days');
    }
    if (newFromMoment < minDate || newFromMoment > toMoment) {
      throw Error(ErrorCode.invalidDateRange);
    }
    this.setState({ fromMoment: newFromMoment });
  };

  getAndSetNextPrevDateTo = async (direction, selectedDay, fromMoment, maxDate) => {
    let newToMoment;
    if (direction === 'prev') {
      newToMoment = moment.utc(selectedDay).add(-1, 'days');
    } else {
      newToMoment = moment.utc(selectedDay).add(1, 'days');
    }
    if (newToMoment > maxDate || newToMoment < fromMoment) {
      throw Error(ErrorCode.invalidDateRange);
    }
    this.setState({ toMoment: newToMoment });
  };

  render() {
    const {
      minDate,
      maxDate,
      dataSourcesInitialized,
      selectedThemeId,
      selectedTiles,
      isExpanded,
      searchError,
      searchInProgress,
      searchResult,
      resultsPanelSelected,
      resultsAvailable,
      aoiGeometry,
    } = this.props;
    const minDateRange = moment.utc(minDate ? minDate : '2014-04-03').startOf('day');
    const maxDateRange = moment.utc(maxDate).endOf('day');
    const { selectedCollections, maxCc, selectedFilters } = this.state.collectionForm;
    const { fromMoment, toMoment, displayCalendarFrom, displayCalendarTo, additionFiltersPositionTop } =
      this.state;

    return (
      <div className="search-panel">
        <div className="top-label">
          <div className="data-source-advanced-title">{'Data sources'}:</div>

          <div className="checkbox-group">
            <div className="column" key={selectedThemeId || ''}>
              <CollectionForm
                selectedCollections={selectedCollections}
                maxCc={maxCc}
                setSelectedCollections={this.setSelectedCollections}
                setMaxCc={this.setMaxCc}
                collections={taskCollections}
                // setMaxCc={() => {}}
                // selectedFilters={selectedFilters}
                selectedFilters={[]}
                setSelectedFilters={this.setSelectedFilters}
                resetSelectedFilters={this.resetSelectedFilters}
                setAdditionalFiltersPositionTop={this.setAdditionalFiltersPositionTop}
                additionFiltersPositionTop={additionFiltersPositionTop}
                isAdditionalFilters={false}
              />
            </div>
          </div>
        </div>
        <div className="select-time-range">
          <div className="time-range-advanced-title">{'Time range'}:</div>
          <TimespanPicker
            id="visualization-time-select"
            minDate={minDateRange}
            maxDate={maxDateRange}
            timespan={{ fromTime: fromMoment, toTime: toMoment }}
            applyTimespan={(fromTime, toTime) => this.setState({ fromMoment: fromTime, toMoment: toTime })}
            timespanExpanded={true}
            calendarHolder={this.calendarHolder}
            displayCalendarFrom={displayCalendarFrom}
            openCalendarFrom={() => this.setState({ displayCalendarFrom: true })}
            closeCalendarFrom={() => this.setState({ displayCalendarFrom: false })}
            displayCalendarUntil={displayCalendarTo}
            openCalendarUntil={() => this.setState({ displayCalendarTo: true })}
            closeCalendarUntil={() => this.setState({ displayCalendarTo: false })}
            showNextPrevDateArrows={true}
            getAndSetNextPrevDateFrom={async (direction, selectedDay) =>
              await this.getAndSetNextPrevDateFrom(direction, selectedDay, toMoment, minDateRange)
            }
            getAndSetNextPrevDateTo={async (direction, selectedDay) =>
              await this.getAndSetNextPrevDateTo(direction, selectedDay, fromMoment, maxDateRange)
            }
          />

          <div className="calendar-holder" ref={this.calendarHolder} />
        </div>
        <div className="task-panel">
          <div className="task-panel__title">Info:</div>
          <div className="task-panel__subtitle">Name:</div>
          <div className="task-panel__name">
            <input
              type="text"
              placeholder={'Task Name'}
              value={this.state.taskName}
              onChange={(e) => this.setState({ taskName: e.target.value })}
            />
          </div>
          <div className="task-panel__filter">
            <div className="task-panel__subtitle">Type:</div>
            <Select
              placeholder={`No type selected`}
              styles={customSelectStyle}
              menuPosition="fixed"
              menuShouldBlockScroll={true}
              className="theme-select-dropdown"
              classNamePrefix="theme-select"
              components={{ DropdownIndicator }}
              value={TaskTypes.find((a) => a.value === this.state.taskType)}
              onChange={(e) => this.setState({ taskType: e.value })}
              options={[
                {
                  label: 'Ship Detection',
                  value: 'ship detection',
                },
                {
                  label: 'Iceberg Detection',
                  value: 'iceberg detection',
                },
                {
                  label: 'Airplane Detection',
                  value: 'airplane detection',
                },
              ]}
            />
          </div>
          {aoiGeometry && (
            <>
              <div className="task-panel__subtitle">Area:</div>
              <div className="task-panel__desc">
                {uniq(aoiGeometry.coordinates[0].map((point) => `(${point[1]},${point[0]})`)).join(', ')}
              </div>
            </>
          )}
          {this.state.taskError && <div className="task-panel__error">{this.state.taskError}</div>}
          <EOBButton
            className="task-panel__btn"
            onClick={this.handleCreateTask}
            fluid
            text={'Create Task'}
            loading={this.state.isTaskCreating}
          />
          <ToastContainer />
        </div>
      </div>
    );
  }
}

const mapStoreToProps = (store) => ({
  zoom: store.mainMap.zoom,
  dataSourcesInitialized: store.themes.dataSourcesInitialized,
  mapBounds: store.mainMap.bounds,
  aoiBounds: store.aoi.bounds,
  is3D: store.mainMap.is3D,
  datasetId: store.visualization.datasetId,
  selectedTiles: store.searchResults.selectedTiles,
  searchResult: store.searchResults.searchResult,
  searchFormData: store.searchResults.searchFormData,
  resultsAvailable: store.searchResults.resultsAvailable,
  resultsPanelSelected: store.searchResults.resultsPanelSelected,
  user: store.auth.user.userdata,
  selectedModeId: store.themes.selectedModeId,
  selectedThemeId: store.themes.selectedThemeId,
  themesLists: store.themes.themesLists,
  selectedThemesListId: store.themes.selectedThemesListId,
  selectedLanguage: store.language.selectedLanguage,
  selectedTab: store.tabs.selectedTabSearchPanelIndex,
  terrainViewerId: store.terrainViewer.id,
  aoiGeometry: store.aoi.geometry,
});

export default connect(mapStoreToProps, null)(TaskPanel);
