import { Tag } from 'antd';
import { addChat, createTask, getAllTasks, getChatLog, getMessage } from 'api';
import axios from 'axios';
import { ChatMessage } from 'components';
import { useAuth } from 'contexts';
import { cloneDeep, concat, uniq } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useRef, useState, useMemo } from 'react';
import { IoMdSend } from 'react-icons/io';
import 'react-medium-image-zoom/dist/styles.css';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { groupChats } from 'utils/chat';
import { centerPositionFromBounds } from 'utils/map';
import { CustomDropdownIndicator } from '../../components/CustomSelectInput/CustomDropdownIndicator';
import { customSelectStyle, taskSelectStyle } from '../../components/CustomSelectInput/CustomSelectStyle';
import { TimespanPicker } from '../../components/TimespanPicker/TimespanPicker';
import { ReactComponent as ChevronDown } from '../../icons/chevron-down.svg';
import { ReactComponent as MagnifierSvg } from '../../icons/magnifier.svg';
import store, { aoiSlice, mainMapSlice } from '../../store';
import { withODataSearchHOC } from '../VisualizationPanel/CollectionSelection/AdvancedSearch/withODataSearchHOC';
import './../../../Cupernicus/Tools/VisualizationPanel/ThemeSelect/ThemeSelect.scss';
import CollectionForm from './../VisualizationPanel/CollectionSelection/AdvancedSearch/CollectionForm';
import './style.scss';
import { taskCollections } from '../VisualizationPanel/CollectionSelection/AdvancedSearch/collectionFormConfig';

const TaskTypes = [
  {
    label: 'Ship Detection',
    value: 'ship detection',
  },
  {
    label: 'Iceberg Detection',
    value: 'iceberg detection',
  },
  {
    label: 'Airplane Detection',
    value: 'airplane detection',
  },
];
const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <CustomDropdownIndicator {...props} magnifier={MagnifierSvg} chevronDown={ChevronDown} />
      </components.DropdownIndicator>
    )
  );
};
const getLocation = async (params) => {
  try {
    const url = `https://gisco-services.ec.europa.eu/api/?q=${params}&limit=5`;
    const { data } = await axios(url);

    const locations = data.features.map((location) => {
      const label = [
        ...(location.properties.name ? [location.properties.name] : []),
        ...(location.properties.district ? [location.properties.district] : []),
        ...(location.properties.city ? [location.properties.city] : []),
        ...(location.properties.country ? [location.properties.country] : []),
      ];

      return {
        placeId: location.properties.osm_id,
        label: label.join(', '),
        location: location.geometry.coordinates,
      };
    });
    console.log('locations', locations);
    if (locations.length > 0) {
      // onNavigate();
      return locations[0].location;
    }
  } catch (e) {
    console.error(e);
  }
};
const ErrorCode = {
  noProductsFound: 'noProductsFound',
  selectSearchCriteria: 'selectSearchCriteria',
  invalidTimeRange: 'invalidTimeRange',
  invalidDateRange: 'invalidDateRange',
};
const ChatBot = ({ onClose, onNavigate, messages, setMessages, bbox, lat, lng }) => {
  const [step, setStep] = useState('');
  const [isInputDisable, setisInputDisable] = useState(false);
  const messagesEndRef = useRef(null);
  const [isTaskCreating, setIsTaskCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fromMoment, setFromMoment] = useState(moment.utc().subtract(1, 'month').startOf('day'));
  const [toMoment, setToMoment] = useState(moment.utc().endOf('day'));
  const [displayCalendarFrom, setDisplayCalendarFrom] = useState(false);
  const [displayCalendarTo, setDisplayCalendarTo] = useState(false);
  const [taskType, setTaskType] = useState('ship detection');
  const calendarHolder = useRef(null);
  const [currentIntent, setCurrentIntent] = useState('');
  const { userInfo } = useAuth();
  const [collectionForm, setCollectionForm] = useState({
    selectedCollections: {},
    maxCc: {},
    selectedFilters: {},
  });
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const [lastTags, setLastTags] = useState();
  const [askFor, setAskFor] = useState([]);
  const [collections, setCollections] = useState([]);
  const [collectionError, setCollectionError] = useState('');
  const [allTasks, setAllTasks] = useState([]);
  const [taskSelection, setTaskSelection] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [currentLocation, setCurrentLocation] = useState('');
  const [isTaskSelected, setIsTaskSelected] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  }, [messages]);

  useQuery('chatLog', () => getChatLog(), {
    onSuccess: (res) => {
      if (res.data.length && res.data.length > 0) {
        setMessages(res.data.map((msg) => msg.data));
        setTimeout(() => {
          scrollToBottom();
        }, 1000);
      } else {
        appendMessage([{ text: 'How can I help you?', sender: 'bot' }], 'other');
      }
    },
    onError: (error) => {},
  });
  useQuery('allTasks', () => getAllTasks(), {
    onSuccess: (res) => {
      setAllTasks(res.result);
    },
    onError: (error) => {},
  });

  const getAndSetNextPrevDateFrom = async (direction, selectedDay, toMoment, minDate) => {
    let newFromMoment;
    if (direction === 'prev') {
      newFromMoment = moment.utc(selectedDay).add(-1, 'days');
    } else {
      newFromMoment = moment.utc(selectedDay).add(1, 'days');
    }
    if (newFromMoment < minDate || newFromMoment > toMoment) {
      throw Error(ErrorCode.invalidDateRange);
    }
    setFromMoment(newFromMoment);
  };

  const getAndSetNextPrevDateTo = async (direction, selectedDay, fromMoment, maxDate) => {
    let newToMoment;
    if (direction === 'prev') {
      newToMoment = moment.utc(selectedDay).add(-1, 'days');
    } else {
      newToMoment = moment.utc(selectedDay).add(1, 'days');
    }
    if (newToMoment > maxDate || newToMoment < fromMoment) {
      throw Error(ErrorCode.invalidDateRange);
    }
    setToMoment(newToMoment);
  };

  const setSelectedCollections = (selectedCollections) => {
    const { maxCc, selectedFilters } = collectionForm;
    const newSelectedFilters = {};
    setCollectionError('');
    console.log('selectedCollections', selectedCollections);
    //remove filters for unselected collections
    const filtersForUnselectedCollections = Object.keys(selectedFilters).filter(
      (collectionFilterKey) => !Object.keys(selectedCollections).find((key) => key === collectionFilterKey),
    );

    filtersForUnselectedCollections.forEach((c) => delete newSelectedFilters?.[c]);
    const newCollectionFormState = {
      selectedCollections: cloneDeep(selectedCollections),
      maxCc: cloneDeep({ ...maxCc }),
      selectedFilters,
    };
    setCollectionForm((state) => ({ ...state, ...cloneDeep(newCollectionFormState) }));
  };

  const setMaxCc = (maxCc) => {
    const { selectedCollections, selectedFilters } = collectionForm;
    const newCollectionFormState = {
      selectedCollections: cloneDeep(selectedCollections),
      maxCc: cloneDeep(maxCc),
      selectedFilters: cloneDeep(selectedFilters),
    };
    setCollectionForm((state) => ({ ...state, ...cloneDeep(newCollectionFormState) }));
  };

  const handleCancelTask = () => {
    setisInputDisable(false);
    setStep('');
    setAskFor([]);
    appendMessage([{ text: 'Your task creation is cancelled', sender: 'bot' }]);
    setCurrentIntent('other');
    appendMessage([{ text: 'How can I help you?', sender: 'bot' }], 'other');
    store.dispatch(aoiSlice.actions.reset());
  };
  const handleCreateTask = async () => {
    setStep('');
    setIsTaskCreating(true);
    const qp = queryString.parse(window.location.search);

    await createTask({
      name: lastTags.task_name,
      type: lastTags.task_type,
      lat: lastTags.latitude,
      lng: lastTags.langitude,
      zoom: qp.zoom,
      datasetId: qp.datasetId,
      themeId: qp.themeId,
      visualizationUrl: qp.visualizationUrl,
      bbox: bbox,
      fromDate: lastTags.start_time,
      toDate: lastTags.end_time,
      collections,
    });
    toast.success('Created Task Successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    getAllTasks().then((tasks) => {
      store.dispatch(mainMapSlice.actions.setTasks(tasks));
      setAllTasks(tasks);
    });

    store.dispatch(
      mainMapSlice.actions.setPosition({
        lat: lastTags.latitude,
        lng: lastTags.langitude,
      }),
    );

    store.dispatch(aoiSlice.actions.reset());
    setIsTaskCreating(false);

    await appendMessage([{ text: 'Created task successfully!', sender: 'bot' }]);
    await appendMessage([{ text: 'How can I help you?', sender: 'bot' }], 'other');
    setAskFor([]);
    setCurrentIntent('');
    setLastTags();
    setCollections([]);
    setisInputDisable(false);
  };

  const appendMessage = async (payload, intent) => {
    setMessages((messages) => [
      ...messages,
      ...payload.map((msg) => ({ ...msg, intent: intent || currentIntent || msg.intent })),
    ]);
    await Promise.all(
      payload.map((msg) => addChat({ ...msg, intent: intent || currentIntent || msg.intent })),
    );
  };
  const sendMessage = async (userInput) => {
    if (!userInput.trim()) return;

    // Add user message to chat
    setMessages((messages) => [...messages, { text: userInput, sender: 'user' }]);
    setIsLoading(true);

    const res = await getMessage({
      message: userInput,
      ids: isTaskSelected ? getMetaIds() : [],
      user_id: userInfo.id,
      place: currentLocation,
    });
    setIsLoading(false);
    setLastTags(res.assistant.tags);
    //setisInputDisable(true);
    setCurrentIntent(res.assistant.intent);
    // await appendMessage([{ text: userInput, sender: 'user' }], res.assistant.intent);
    setMessages((messages) =>
      messages.map((msg, index) => {
        if (index === messages.length - 1) {
          return {
            ...msg,
            intent: res.assistant.intent,
          };
        }
        return msg;
      }),
    );
    addChat({ text: userInput, sender: 'user', intent: res.assistant.intent });
    if (res.assistant.intent === 'inquire_place') {
      console.log('res', res);
      const location = await getLocation(res.assistant.arguments.location);
      setAskFor([]);
      onNavigate(location);
    } else if (res.assistant.intent === 'create_task') {
      if (res.assistant.status === 'complete') {
        setisInputDisable(true);
        await appendMessage([{ text: 'Please select area.', sender: 'bot', intent: res.assistant.intent }]);
        setStep('area');
      } else if (res.assistant.status === 'incomplete') {
        setisInputDisable(true);
        let temp = [...['latitude', 'langitude'], ...askFor, ...(res?.assistant?.follow_up || [])];
        // Sort askFor
        let newAskFor = [];
        if (temp.includes('location')) {
          newAskFor.push('location');
        }
        if (temp.includes('latitude')) {
          newAskFor.push('latitude');
        }
        if (temp.includes('langitude')) {
          newAskFor.push('langitude');
        }
        if (temp.includes('start_time')) {
          newAskFor.push('start_time');
        }
        if (temp.includes('end_time')) {
          newAskFor.push('end_time');
        }
        if (temp.includes('collection')) {
          newAskFor.push('collection');
        }
        if (temp.includes('task_type')) {
          newAskFor.push('task_type');
        }
        setAskFor(newAskFor);
      }
      if (res.assistant?.tags?.location) {
        // const location = await getLocation(userInput);
        onNavigate([res.assistant.tags.langitude, res.assistant.tags.latitude]);
      }
    } else if (res.assistant.intent === 'change_tasks') {
      setAskFor(['task_selection']);
      await appendMessage([{ text: 'Please select tasks.', sender: 'bot', intent: res.assistant.intent }]);
    } else {
      if (res.assistant.follow_up) {
        setAskFor(res.assistant.follow_up);
      } else if (res.assistant.messages) {
        await appendMessage(res.assistant.messages, res.assistant.intent);
      } else {
        await appendMessage([
          { text: 'Please write correct questions.', sender: 'bot', intent: res.assistant.intent },
        ]);
      }
    }
  };
  const handleConfirmTimeRange = () => {
    setAskFor((askFor) => askFor.filter((item) => !['start_time', 'end_time'].includes(item)));

    appendMessage([
      {
        text: `You selected time range from ${fromMoment.format('YYYY-MM-DD')} to ${toMoment.format(
          'YYYY-MM-DD',
        )}`,
        sender: 'bot',
      },
    ]);
    setLastTags((lastTags) => ({
      ...lastTags,
      start_time: fromMoment.format('YYYY-MM-DD'),
      end_time: fromMoment.format('YYYY-MM-DD'),
    }));
  };
  const handleConfirmCollection = () => {
    const coll = collectionForm.selectedCollections;
    let datasets = [];
    setCollectionError('');
    console.log('coll', coll);
    if (coll) {
      for (let key1 of Object.keys(coll)) {
        if (key1 === 'Maxar') {
          for (let key2 of Object.keys(coll[key1])) {
            datasets.push(key2);
          }
        } else {
          for (let key2 of Object.keys(coll[key1])) {
            if (coll[key1][key2]) {
              for (let key3 of Object.keys(coll[key1][key2])) {
                if (coll[key1][key2][key3]) {
                  datasets.push(key3);
                }
              }
            }
          }
        }
      }
    }
    if (datasets.length > 0) {
      setCollections(datasets);
      setAskFor((askFor) => askFor.filter((item) => !['collection'].includes(item)));

      appendMessage([
        {
          text: `You selected ${datasets.join(', ')} collections.`,
          sender: 'bot',
        },
      ]);
      setSelectedCollections({});
    } else {
      setCollectionError('Please select at least one collection');
    }
  };
  const handleConfirmArea = () => {
    setAskFor((askFor) => askFor.filter((item) => !['latitude', 'langitude', 'location'].includes(item)));

    appendMessage([
      {
        text: ` You selected:     ${uniq(
          bbox.coordinates[0].map((point) => `(${point[1]},${point[0]})`),
        ).join('\n ')}`,
        sender: 'bot',
      },
    ]);
    setLastTags((lastTags) => ({ ...lastTags, latitude: lat, langitude: lng, bbox }));
  };
  const handleConfirmType = () => {
    if (taskType) {
      setAskFor((askFor) => askFor.filter((item) => !['task_type'].includes(item)));
      setLastTags((lastTags) => ({ ...lastTags, task_type: taskType }));
      appendMessage([
        {
          text: `You selected ${taskType} type.`,
          sender: 'bot',
        },
      ]);
    }
  };
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        handleClickCancel();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [currentIntent]);
  const handleClickCancel = () => {
    if (currentIntent === 'create_task') {
      setCurrentIntent('exit_task');
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    }
  };
  const handleClickReset = () => {
    if (currentIntent === 'create_task') {
      setCurrentIntent('reset_task');
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    }
  };
  const handleExitTask = () => {
    setCurrentIntent('');
    setAskFor([]);
    appendMessage([
      { text: 'You cancelled the creating a task', sender: 'bot' },
      { text: 'How can I help you?', sender: 'bot', intnet: 'other' },
    ]);
    setisInputDisable(false);
    setStep('');
    store.dispatch(aoiSlice.actions.reset());
  };
  const handleResetTask = () => {
    setCurrentIntent('create_task');
    appendMessage([{ text: "Let's reset the task info.", sender: 'bot' }], 'create_task');
    setAskFor(['location', 'latitude', 'langitude', 'start_time', 'end_time', 'collection', 'task_type']);
  };

  const getMetaIds = () => {
    let metaids = [];
    if (taskSelection.map((e) => e.value).includes('all')) {
      metaids = allTasks
        .filter((task) => task.status === 'completed')
        .map((task) => task.metaids)
        .reduce((a, b) => [...a, ...b], []);
    } else {
      metaids = allTasks
        .filter((t) => taskSelection.map((task) => task.value).includes(t._id))
        .map((task) => task.metaids)
        .reduce((a, b) => [...a, ...b], []);
    }
    return metaids;
  };
  const handleTaskSelectionConfirm = async () => {
    setAskFor((askFor) => askFor.filter((item) => !['task_selection'].includes(item)));

    appendMessage([
      {
        text: `You selected ${taskSelection.map((task) => task.label).join(', ')} tasks.`,
        sender: 'bot',
      },
    ]);
    setSelectedTasks(taskSelection);
    const bounds = taskSelection.map((e) => e.value).includes('all')
      ? concat(...allTasks.slice(0, 1).map((task) => task.bbox.coordinates[0]))
      : concat(
          ...allTasks
            .filter((t) => taskSelection.map((task) => task.value).includes(t._id))
            .slice(0, 1)
            .map((task) => task.bbox.coordinates[0]),
        );

    const centerPos = centerPositionFromBounds(bounds);
    store.dispatch(
      mainMapSlice.actions.setPosition({
        lat: centerPos[1],
        lng: centerPos[0],
        zoom: 8,
      }),
    );
    // store.dispatch(mainMapSlice.actions.setBounds({ bounds: getBoundsRect(bounds) }));

    setIsTaskSelected(true);
    if (currentIntent !== 'change_tasks') {
      setIsLoading(true);
      const metaids = getMetaIds();
      const res = await getMessage({
        message: messages.filter((msg) => msg.sender === 'user').slice(-1)[0].text,
        ids: metaids,
        taskNames: taskSelection.map((task) => task.label),
      });
      setIsLoading(false);
      setCurrentIntent(res.assistant.intent);
      if (res.assistant.follow_up) {
        setAskFor(res.assistant.follow_up);
      } else if (res.assistant.messages) {
        await appendMessage(res.assistant.messages, res.assistant.intent);
      } else {
        await appendMessage([
          { text: 'Please write correct questions.', sender: 'bot', intent: res.assistant.intent },
        ]);
      }
    }
  };
  useEffect(() => {
    if (askFor.length > 0) {
      if (['start_time', 'end_time'].includes(askFor[0])) {
        appendMessage([
          {
            text: 'Please select time range',
            sender: 'bot',
          },
        ]);
      } else if (['location'].includes(askFor[0])) {
        appendMessage([
          {
            text: 'Please navigate to the location and select area',
            sender: 'bot',
          },
        ]);
      } else if (['latitude', 'langitude'].includes(askFor[0])) {
        appendMessage([
          {
            text: 'Please select area',
            sender: 'bot',
          },
        ]);
      } else if (['collection'].includes(askFor[0])) {
        appendMessage([
          {
            text: 'Please select collection',
            sender: 'bot',
          },
        ]);
      } else if (['task_type'].includes(askFor[0])) {
        appendMessage([
          {
            text: 'Please select task type',
            sender: 'bot',
          },
        ]);
      }
    } else {
      // setMessages((messages) => [...messages, { text: 'How can I help you?', sender: 'bot' }]);
    }
    return () => {};
  }, [askFor]);

  useEffect(() => {
    if (
      currentIntent === 'create_task' &&
      lastTags &&
      lastTags.start_time &&
      lastTags.end_time &&
      lastTags.latitude &&
      lastTags.langitude &&
      lastTags.langitude &&
      lastTags.task_type &&
      collections.length > 0
    ) {
      handleCreateTask();
    }

    return () => {};
  }, [lastTags, collections]);

  const minDateRange = moment.utc('2014-04-03').startOf('day');
  const maxDateRange = moment.utc(undefined).endOf('day');

  const groupMessages = groupChats(messages);

  useEffect(() => {
    if (
      askFor &&
      askFor.length > 0 &&
      ['latitude', 'langitude', 'location'].includes(askFor[0]) &&
      bbox &&
      bbox?.coordinates
    ) {
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    }
    return () => {};
  }, [bbox, askFor]);

  return (
    <div className="chatbot">
      {/* <div className="chatbot-header">
        <div>Assistant</div>
        <span onClick={() => onClose()}>
          <GrClose size={20} />
        </span>
      </div> */}

      <div className="messages">
        {groupMessages.map((group, index) => {
          return (
            <ChatMessage
              onCancel={handleClickCancel}
              onReset={handleClickReset}
              key={index}
              group={group}
              isLast={index === groupMessages.length - 1}
            ></ChatMessage>
          );
        })}

        {bbox && step === 'area' && (
          <>
            <div className="message bot">
              <div>
                You selected{':\n'}
                {uniq(bbox.coordinates[0].map((point) => `(${point[1]},${point[0]})`)).join('\n ')}
              </div>
              <div style={{ marginTop: 12, display: 'flex' }}>
                <button className="chat-bot-button-primary" onClick={handleCreateTask}>
                  Create Task
                </button>
                <button
                  className="chat-bot-button-secondary"
                  onClick={handleCancelTask}
                  style={{ marginLeft: 12 }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        )}
        {isLoading ? (
          <div className="chat-loading">Loading...</div>
        ) : isTaskCreating ? (
          <div className="chat-loading">Loading...</div>
        ) : (
          <></>
        )}
        {askFor.length > 0 && (
          <>
            {['start_time', 'end_time'].includes(askFor[0]) ? (
              <>
                <div className="chat-bot-calendar-wrapper">
                  <TimespanPicker
                    id="chat-time-select"
                    minDate={minDateRange}
                    maxDate={maxDateRange}
                    timespan={{ fromTime: fromMoment, toTime: toMoment }}
                    applyTimespan={(fromTime, toTime) => {
                      setFromMoment(fromTime);
                      setToMoment(toTime);
                    }}
                    timespanExpanded={true}
                    calendarHolder={calendarHolder}
                    displayCalendarFrom={displayCalendarFrom}
                    openCalendarFrom={() => setDisplayCalendarFrom(true)}
                    closeCalendarFrom={() => setDisplayCalendarFrom(false)}
                    displayCalendarUntil={displayCalendarTo}
                    openCalendarUntil={() => setDisplayCalendarTo(true)}
                    closeCalendarUntil={() => setDisplayCalendarTo(false)}
                    showNextPrevDateArrows={true}
                    getAndSetNextPrevDateFrom={async (direction, selectedDay) =>
                      await getAndSetNextPrevDateFrom(direction, selectedDay, toMoment, minDateRange)
                    }
                    getAndSetNextPrevDateTo={async (direction, selectedDay) =>
                      await getAndSetNextPrevDateTo(direction, selectedDay, fromMoment, maxDateRange)
                    }
                  />
                  <div className="calendar-holder" ref={calendarHolder} />
                  <div style={{ marginTop: 20, display: 'flex', justifyContent: 'end' }}>
                    <button className="chat-bot-button-primary" onClick={handleConfirmTimeRange}>
                      Confirm
                    </button>
                  </div>
                </div>
              </>
            ) : ['latitude', 'langitude', 'location'].includes(askFor[0]) ? (
              <>
                {bbox && bbox?.coordinates && (
                  <div className="message bot">
                    <div>
                      <div>
                        You selected{':\n'}
                        {uniq(bbox.coordinates[0].map((point) => `(${point[1]},${point[0]})`)).join('\n ')}
                      </div>
                      <div style={{ marginTop: 12, display: 'flex' }}>
                        <button className="chat-bot-button-primary" onClick={handleConfirmArea}>
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : ['collection'].includes(askFor[0]) ? (
              <div className="chat-bot-collection-wrapper">
                <CollectionForm
                  selectedCollections={{ ...collectionForm.selectedCollections }}
                  maxCc={collectionForm.maxCc}
                  setSelectedCollections={(state) => setSelectedCollections(state)}
                  setMaxCc={(state) => setMaxCc(state)}
                  selectedFilters={[]}
                  setSelectedFilters={() => {}}
                  resetSelectedFilters={() => {}}
                  isAdditionalFilters={false}
                  collections={taskCollections}
                />
                {collectionError && <div className="chat-bot-collection-error">{collectionError}</div>}
                <div style={{ marginTop: 20, display: 'flex', justifyContent: 'end' }}>
                  <button className="chat-bot-button-primary" onClick={handleConfirmCollection}>
                    Confirm
                  </button>
                </div>
              </div>
            ) : ['task_type'].includes(askFor[0]) ? (
              <div className="chat-bot-collection-wrapper">
                <Select
                  placeholder={`No type selected`}
                  styles={customSelectStyle}
                  menuPosition="fixed"
                  menuShouldBlockScroll={true}
                  className="theme-select-dropdown"
                  classNamePrefix="theme-select"
                  components={{ DropdownIndicator }}
                  defaultValue={TaskTypes.find((a) => a.value === taskType)}
                  value={TaskTypes.find((a) => a.value === setTaskType)}
                  onChange={(e) => setTaskType(e.value)}
                  options={[
                    {
                      label: 'Ship Detection',
                      value: 'ship detection',
                    },
                    {
                      label: 'Iceberg Detection',
                      value: 'iceberg detection',
                    },
                    {
                      label: 'Airplane Detection',
                      value: 'airplane detection',
                    },
                  ]}
                />{' '}
                <div style={{ marginTop: 20, display: 'flex', justifyContent: 'end' }}>
                  <button className="chat-bot-button-primary" onClick={handleConfirmType}>
                    Confirm
                  </button>
                </div>
              </div>
            ) : ['task_selection'].includes(askFor[0]) ? (
              <div className="chat-bot-collection-wrapper">
                <Select
                  placeholder={`Please select task...`}
                  styles={customSelectStyle}
                  menuPosition="fixed"
                  menuShouldBlockScroll={true}
                  className="theme-select-dropdown"
                  isMulti
                  classNamePrefix="theme-select"
                  components={{ DropdownIndicator }}
                  defaultValue={TaskTypes.find((a) => a.value === taskType)}
                  value={taskSelection}
                  onChange={(e) => {
                    if (e.map((e) => e.value).includes('all')) {
                      setTaskSelection([{ label: 'All', value: 'all' }]);
                    } else {
                      setTaskSelection(e);
                    }
                  }}
                  options={[
                    ...allTasks
                      .filter((task) => task.status === 'completed')
                      .map((task) => ({ label: task.name, value: task._id })),
                    { label: 'All', value: 'all' },
                  ]}
                />{' '}
                <div style={{ marginTop: 20, display: 'flex', justifyContent: 'end' }}>
                  <button className="chat-bot-button-primary" onClick={handleTaskSelectionConfirm}>
                    Confirm
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
        {currentIntent === 'exit_task' && (
          <div className="chat-bot-exit-wrapper">
            <div style={{ marginTop: 20 }}>Are you sure to cancel creating task?</div>
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
              <button className="chat-bot-button-secondary" onClick={() => setCurrentIntent('create_task')}>
                Cancel
              </button>
              <button className="chat-bot-button-primary" onClick={handleExitTask}>
                Confirm
              </button>
            </div>
          </div>
        )}
        {currentIntent === 'reset_task' && (
          <div className="chat-bot-exit-wrapper">
            <div style={{ marginTop: 20 }}>Are you sure to reset task info?</div>
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
              <button className="chat-bot-button-secondary" onClick={() => setCurrentIntent('create_task')}>
                Cancel
              </button>
              <button className="chat-bot-button-primary" onClick={handleResetTask}>
                Confirm
              </button>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      {selectedTasks.length > 0 && (
        <div className="chatbot-current-info">
          <div style={{ minWidth: 110, fontSize: 14 }}>Selected Tasks:</div>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
            {selectedTasks.map((task) => (
              <Tag style={{ textDecoration: 'underline', color: '#3e7cd2' }} key={task.value}>
                <a href={`/task?id=${task.value}`} target="_blank" rel="noreferrer">
                  {task.label}
                </a>{' '}
              </Tag>
            ))}
            {/* <Select
              defaultValue={selectedTasks}
              isMulti
              styles={taskSelectStyle}
              menuPosition="fixed"
              menuShouldBlockScroll={true}
              className="theme-select-dropdown"
              name="colors"
              onChange={(e) => {
                setTaskSelection(e);
              }}
              options={allTasks
                .filter((task) => task.status === 'completed')
                .map((task) => ({ label: task.name, value: task._id }))}
              classNamePrefix="select"
            /> */}
          </div>
        </div>
      )}
      {currentLocation && (
        <div className="chatbot-current-info">
          <div style={{ minWidth: 110, fontSize: 14 }}>Current Location:</div>
          <div>{currentLocation}</div>
        </div>
      )}
      <ChatInput disabled={isLoading || isInputDisable} onSendMessage={sendMessage} onNavigate={onNavigate} />

      {/* <ToastContainer /> */}
    </div>
  );
};
const ChatInput = ({ onSendMessage, disabled }) => {
  const [input, setInput] = useState('');

  const handleSend = () => {
    onSendMessage(input);
    setInput('');
  };

  return (
    <div className="chat-input">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleSend()}
        placeholder="Type a message..."
        disabled={disabled}
      />

      <span onClick={handleSend}>
        <IoMdSend size={24} color="#4caf50" />
      </span>
    </div>
  );
};
const AvaPanel = ({ onNavigate = () => {}, aoiGeometry, lat, lng }) => {
  const [messages, setMessages] = useState([]);
  return (
    <div className="chatbot-holder">
      <ChatBot
        messages={messages}
        setMessages={setMessages}
        onNavigate={onNavigate}
        headerTitle="Assistant"
        bbox={aoiGeometry}
        lat={lat}
        lng={lng}
      />
    </div>
  );
};

const mapStoreToProps = (store) => ({
  zoom: store.mainMap.zoom,
  aoiGeometry: store.aoi.geometry,
  lat: store.mainMap.lat,
  lng: store.mainMap.lng,
});
export default connect(mapStoreToProps, null)(withODataSearchHOC(AvaPanel));
