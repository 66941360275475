import { Button, Card, Col, Descriptions, Modal, Row, Tooltip } from 'antd';
import { getTaskById } from 'api';
import { format } from 'date-fns';
import { MainLayout } from 'layout';
import queryString from 'query-string';
import { useRef, useState } from 'react';
import { FaImages, FaRegTrashAlt } from 'react-icons/fa';
import { FiEdit3 } from 'react-icons/fi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { Map, Rectangle, TileLayer } from 'react-leaflet';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { EditTask, Gallery, ReportModal } from './components';
import { capitalize } from 'lodash';
import './style.scss';

export const TaskInfoPage = () => {
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const id = qs?.id;
  const actionRef = useRef();
  const mapRef = useRef();
  const [openImages, setOpenImages] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);

  const { data, isLoading } = useQuery(['taskById', id], () => getTaskById(id), {
    onSuccess: (res) => {},
    onError: (error) => {},
  });

  const handleShowImages = (data) => {
    setOpenImages(true);
  };

  const handleShowReport = (data) => {
    setIsReportOpen(true);
  };

  const handleEditTask = (data) => {
    setIsEditOpen(true);
  };

  return (
    <MainLayout titleText={data ? `${data.name}` : ''}>
      <Row style={{ margin: 24 }} gutter={[16, 16]}>
        <Col span={24}>
          {isLoading ? (
            <Card>Loading...</Card>
          ) : data ? (
            <>
              <Card title="Task Information">
                <Descriptions
                  layout="vertical"
                  items={[
                    {
                      key: '1',
                      label: 'Name',
                      children: data.name,
                    },
                    {
                      key: '2',
                      label: 'Type',
                      children: data.type,
                    },
                    {
                      key: '3',
                      label: 'Location',
                      span: 2,
                      children: data.location,
                    },
                    {
                      key: '4',
                      label: 'Status',
                      children: capitalize(data.status === 'downloaded' ? 'processing' : data.status),
                    },
                    {
                      key: '6',
                      label: 'Created At',
                      children: format(new Date(data.createdAt), 'MM/dd/yyyy hh:mm:ss'),
                    },
                  ]}
                />
                <Row gutter={[16, 16]}>
                  <Col>
                    <Button icon={<FiEdit3 />} onClick={() => handleEditTask(data)}></Button>
                  </Col>
                  <Col>
                    <Button icon={<FaRegTrashAlt />} onClick={() => handleEditTask(data)}></Button>
                  </Col>
                  {data.metaids && Array.isArray(data?.metaids) && data.metaids.length > 0 && (
                    <Col>
                      <Tooltip>
                        <Button icon={<FaImages />} onClick={() => handleShowImages(data)}></Button>
                      </Tooltip>
                    </Col>
                  )}
                  {data?.status === 'completed' && (
                    <Col>
                      <Tooltip>
                        <Button
                          icon={<HiOutlineDocumentReport />}
                          onClick={() => handleShowReport(data)}
                        ></Button>
                      </Tooltip>
                    </Col>
                  )}
                </Row>
              </Card>
              <Card title="Location" style={{ height: 500, marginTop: 30 }}>
                <div id="report-map-wrapper">
                  <Map ref={mapRef} bounds={data.bbox.coordinates[0].map((item) => [item[1], item[0]])}>
                    {/* <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    /> */}
                    <TileLayer
                      url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWtpcmFpdG8wMTE2IiwiYSI6ImNrb3VrMWhzZjAxODcyd3FjdzlqcjZ2YXUifQ.MI6SNR-kKNpvex_as9ukTQ`}
                      attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
                    />
                    <Rectangle
                      bounds={data.bbox.coordinates[0].map((item) => [item[1], item[0]])}
                      color={'red'}
                    />
                  </Map>
                </div>
              </Card>
            </>
          ) : (
            <></>
          )}
        </Col>
        {/* <Drawer title="Images" onClose={() => setOpenImages(false)} open={openImages}>
          {selectedTask &&
            selectedTask.analytics.length > 0 &&
            selectedTask.analytics[0].gallery.map((item, index) => (
              <Zoom key={`${index}key`}>
                <img src={item.img} width={300} height={150} alt="message" />
              </Zoom>
            ))}
        </Drawer> */}
        <Modal
          title="Images"
          centered
          open={openImages}
          onOk={() => setOpenImages(false)}
          onCancel={() => setOpenImages(false)}
          width={1400}
        >
          <Gallery task={data} />
        </Modal>
        {data && isReportOpen && (
          <ReportModal isReportOpen={isReportOpen} setIsReportOpen={(e) => setIsReportOpen(e)} task={data} />
        )}
        {data && isEditOpen && (
          <EditTask
            data={data}
            open={!!data}
            onOpenChange={() => {
              setIsEditOpen(false);
            }}
            onEdit={() => {
              actionRef.current.reload();
            }}
          />
        )}
      </Row>
    </MainLayout>
  );
};
