import { ProForm, ProFormSelect, ProFormDateTimePicker, ProFormText } from '@ant-design/pro-components';
import { Alert, Modal, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { updateDataById } from 'api';
import dayjs from 'dayjs';

export const EditData = ({ open, onOpenChange, onEdit = () => {}, data }) => {
  const formRef = useRef();
  const [error, setError] = useState('');
  useEffect(() => {
    if (!open) {
      formRef.current?.resetFields();
      setError('');
    }

    return () => {};
  }, [open]);

  return (
    <Modal
      title={'Edit'}
      width="400px"
      open={open}
      onFinish={async (values) => {}}
      onCancel={async () => {
        onOpenChange(false);
      }}
      onOpenChange={(visible) => {
        formRef.current?.resetFields();
        onOpenChange(visible);
      }}
      footer={null}
    >
      <ProForm
        formRef={formRef}
        initialValues={{
          name: data.name,
          resolution: data.resolution,
          location: data.location,
          date: dayjs(data.date),
        }}
        onFinish={async (values) => {
          setError('');
          const res = await updateDataById(data._id, {
            date: values.date,
          });
          if (res.status === 'error') {
            setError(res.message);
          } else {
            onEdit();
            onOpenChange(false);
            message.success('Updated the data successfully!');
          }
        }}
      >
        <ProFormText
          name="name"
          label={'Name'}
          placeholder="Name"
          rules={[
            {
              required: true,
            },
          ]}
          disabled
        />
        <ProFormText
          name="resolution"
          label={'Resolution'}
          placeholder="Resolution"
          rules={[
            {
              required: true,
            },
          ]}
          disabled
        />
        <ProFormText
          name="location"
          label={'Location'}
          placeholder="Location"
          rules={[
            {
              required: true,
            },
          ]}
          disabled
        />
        <ProFormDateTimePicker
          name="date"
          label={'Date'}
          fullWidth
          style={{ width: '100%', flex: 1 }}
          rules={[
            {
              required: true,
            },
          ]}
        />

        {error && (
          <Alert
            style={{
              marginBottom: 24,
            }}
            message={error}
            type="error"
            showIcon
          />
        )}
      </ProForm>
    </Modal>
  );
};
