import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import { last } from 'lodash';
import { getTaskMeta } from 'api';
import './../style.scss';
import { Collapse, Spin, Modal } from 'antd';
import { useQuery } from 'react-query';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Gallery = ({ task }) => {
  const { data, isLoading } = useQuery(['task_meta', task._id], () => getTaskMeta(task._id), {
    enabled: !!task._id,
    onSuccess: (res) => {},
    onError: (error) => {},
  });

  return (
    <div className="task-img-modal">
      {isLoading ? (
        <div style={{ margin: '20px auto', textAlign: 'center' }}>
          <Spin tip="Loading" size="large"></Spin>
          <div style={{ marginTop: 12 }}>Loading...</div>
        </div>
      ) : data && data.data && data.data.length > 0 ? (
        <Collapse
          accordion
          style={{ width: '100%' }}
          items={data.data.map((item) => ({
            key: item.metaData._id,
            label: `${item.metaData._id} - Date: ${item.metaData.properties.latestAcquisitionDate}, Cloud Clover: ${item.metaData.properties.cloudCover}, GSD: ${item.metaData.properties.groundSampleDistance}`,
            children: <TileGrid data={item.metaData} />,
          }))}
        />
      ) : (
        ''
      )}
    </div>
  );
};

const Tile = ({ row, col, onTileClick, width }) => {
  return (
    <div
      onClick={() => onTileClick(row, col)}
      style={{
        width: width,
        height: width,
        border: '1px solid #eee',
        boxSizing: 'border-box',
        cursor: 'pointer',
        background: 'transparent',
      }}
    />
  );
};
const TileGrid = ({ data }) => {
  const [selectedTile, setSelectedTile] = useState();
  const [cols, setCols] = useState(0);
  const backImgUrl = data.input;
  const urlData = useMemo(() => {
    let maxRow = 0,
      maxCol = 0;
    const newUrls = data.urls
      .filter((url) => last(url.split('/')) !== 'Grid_cell_coordinates.txt')
      .map((url) => {
        const tmp = last(url.split('/')).split('.')[0].split('_');
        const col = parseInt(tmp[0].slice(1));
        const row = parseInt(tmp[1].slice(1));
        if (col > maxCol) {
          maxCol = col;
        }
        if (row > maxRow) {
          maxRow = row;
        }
        return { url, col, row };
      });

    setCols(maxCol);
    let result = [];
    for (let i = 0; i <= maxRow; i++) {
      result[i] = [];
      for (let j = 0; j <= maxCol; j++) {
        result[i][j] = { url: '', thumbs: '' };
      }
    }
    newUrls.forEach((item) => {
      result[item.row][item.col].url = item.url;
    });
    return result;
  }, [data]);

  const imgWidth = useMemo(() => {
    const tmp = 1300 / cols;
    if (tmp > 30) return tmp;
    return 30;
  }, [cols]);

  const handleTileClick = (item) => {
    setSelectedTile(item);
  };

  return (
    <div
      style={{
        overflow: 'auto',
        maxHeight: 500,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0,
          backgroundImage: `url(${backImgUrl})`,
          backgroundSize: '100% 100%',
          width: 'fit-content',
        }}
      >
        {urlData.map((row, rowIdx) => {
          return (
            <div key={`${rowIdx}row`} style={{ display: 'flex', gap: 0, width: 'fit-content' }}>
              {row.map((item, colIdx) => {
                return (
                  <Tile
                    key={`${rowIdx}_${colIdx}`}
                    row={rowIdx}
                    width={imgWidth}
                    col={colIdx}
                    onTileClick={() => handleTileClick(item)}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      {selectedTile && (
        <Modal
          title="View Image"
          centered
          open={!!selectedTile}
          onOk={() => setSelectedTile()}
          onCancel={() => setSelectedTile()}
          width={`calc(100vw - 10px)`}
        >
          <img
            loading="lazy"
            src={selectedTile.url}
            alt=""
            width="100%"
            height="100vh"
            style={{ objectFit: 'contain', height: 'calc(100vh - 140px)' }}
          />
          {/* <LazyLoadImage
            effect="blur"
            src={selectedTile.url} // use normal <img> attributes as props
            width={'100%'}
          /> */}
        </Modal>
      )}
    </div>
  );
};
