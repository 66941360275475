import React, { useState } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import Zoom from 'react-medium-image-zoom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip as Tooltip2,
  ResponsiveContainer,
} from 'recharts';

export const ChatMessage = ({ children, group, isLast, onCancel, onReset }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <div className="message-group">
      {group.messages.length > 1 && (
        <div
          className={`message-group__header ${!isExpanded ? 'collapsed' : ''}`}
          style={{ cursor: 'pointer', color: '#666' }}
          onClick={() => setIsExpanded((state) => !state)}
        >
          {isExpanded ? <MdExpandLess /> : <MdExpandMore />}
        </div>
      )}
      <div className="message-group__main">
        {group.messages.slice(0, isExpanded ? group.messages.length : 1).map((message, idxx) => {
          if (message.text)
            return (
              <div key={`key${idxx}`} className={`message ${message.sender}`}>
                {message.text}
              </div>
            );
          if (message.img)
            return (
              /*<div key={index} className="img">*/
              <div key={`key${idxx}`} style={{ width: 'fit-content', margin: '10px auto' }}>
                <Zoom>
                  <img src={message.img} width={600} height={300} alt="message" />
                </Zoom>
              </div>
            );
          if (message.chart) {
            const data = [];
            message.chart.x.forEach((x, idx) => {
              data.push({
                x: x,
                y: parseInt(message.chart.y[idx]),
              });
            });
            return (
              <div key={`${idxx}key`} style={{ width: 'fit-content', margin: '20px auto' }}>
                <div style={{ textAlign: 'center' }}>{message.chart.title}</div>
                <div>
                  <LineChart
                    width={500}
                    height={360}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 15,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="x" label={{ value: message.chart.xlabel, position: 'center', dy: 20 }} />
                    <YAxis label={{ value: message.chart.ylabel, position: 'center', angle: -90, dy: -20 }} />
                    {/* <Legend /> */}
                    <Tooltip2 />
                    <Line
                      type="monotone"
                      dataKey="y"
                      name={message.chart.ylabel}
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </div>
              </div>
            );
          }

          if (message.table) {
            return (
              <div key={`${idxx}key`} style={{ width: 'fit-content', margin: '20px auto' }}>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(message.table.data).map((key, idx) => (
                        <tr key={idx}>
                          <td>{key}</td>
                          <td>{message.table.data[key]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          } else return null;
        })}
        {isLast && group.intent === 'create_task' && group.messages.length > 1 && (
          <div className="message-buttonbar">
            <button className="message-button" onClick={onCancel}>
              Cancel
            </button>
            <button className="message-button" onClick={onReset}>
              Reset
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
