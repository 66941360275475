import { t } from 'ttag';

const EDUCATION_THEMES = [];

const educationThemesDefaultMode = EDUCATION_THEMES.map((t) => {
  const normalModePostfix = '-NORMAL-MODE';
  const eduThemeNormalMode = { ...t, id: `${t.id}${normalModePostfix}` };
  if (t.pins) {
    eduThemeNormalMode.pins = t.pins.map((p) => ({ ...p, themeId: `${p.themeId}${normalModePostfix}` }));
  }
  return eduThemeNormalMode;
});

export const DEFAULT_THEMES = [
  {
    name: () => t`Default`,
    id: 'DEFAULT-THEME',
    content: [
      {
        name: 'Sentinel-1 EW HH',
        service: 'WMS',
        url: 'https://sh.dataspace.copernicus.eu/ogc/wms/b3bd3bd6-4428-41bd-b81a-fd7e8442ac22',
      },
      // {
      //   name: 'Sentinel-1 EW HH+HV',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'Sentinel-1 EW VV',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'Sentinel-1 EW VV+VH',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'Sentinel-1 IW VV',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'Sentinel-1 IW VV+VH',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'Sentinel-1 IW HH',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'Sentinel-1 IW HH+HV',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'Sentinel-1 SM HH',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'Sentinel-1 SM HH+HV',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'Sentinel-1 SM VV',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'Sentinel-1 SM VV+VH',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      {
        name: 'Sentinel-2 L1C',
        service: 'WMS',
        url: 'https://sh.dataspace.copernicus.eu/ogc/wms/f5ad4136-46d2-44ed-a8f9-5108d3a25ab1',
      },
      {
        name: 'Sentinel-2 L2A',
        service: 'WMS',
        url: 'https://sh.dataspace.copernicus.eu/ogc/wms/699d84d6-8bc7-4eee-8307-2316dfe170e1',
        preselected: true,
      },
      {
        name: 'Sentinel-3 OLCI',
        service: 'WMS',
        url: 'https://sh.dataspace.copernicus.eu/ogc/wms/d3988630-f125-4d37-b0fa-c87b05221629',
        preselected: true,
      },
      {
        name: 'Sentinel-3 SLSTR',
        service: 'WMS',
        url: 'https://sh.dataspace.copernicus.eu/ogc/wms/aee36a4f-0871-4837-8908-9cf73ffeedf1',
      },
      {
        name: 'Sentinel-5P O3 / NO2 / ...',
        service: 'WMS',
        url: 'https://sh.dataspace.copernicus.eu/ogc/wms/999efb8a-3b08-46b1-aba6-886f0287dd99',
      },
      // {
      //   name: 'DEM COPERNICUS_30',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'DEM COPERNICUS_90',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      // },
      // {
      //   name: 'Sentinel-2 Mosaics',
      //   service: 'WMS',
      //   url: 'https://sh.dataspace.copernicus.eu/ogc/wms/317fddf5-0d9e-4331-9307-9a244c312a67',
      //   preselected: true,
      // },
    ],
  },

  ...educationThemesDefaultMode,
];
