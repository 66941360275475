import React from 'react';
import { MainLayout } from 'layout';
import { Stats } from './Stats';
import { Users } from './Users';
import { useQuery } from 'react-query';
import { getAdminStats } from 'api';
import { LuUser } from 'react-icons/lu';
import { HiOutlineUsers } from 'react-icons/hi2';
import { TbUserCog } from 'react-icons/tb';
import { Row, Col } from 'antd';

export const AdminPage = () => {
  const {
    data,
    isStatsLoading,
    refetch: refetchStats,
  } = useQuery('adminStats', () => getAdminStats(), {
    onSuccess: () => {},
    onError: (error) => {},
  });

  return (
    <MainLayout>
      <Row style={{ margin: 24 }} gutter={[16, 16]}>
        <Col span={6}>
          <Stats
            label="Total Members"
            isLoading={isStatsLoading}
            value={data?.totalMembers}
            icon={HiOutlineUsers}
          />
        </Col>
        <Col span={6}>
          <Stats label="Total Users" isLoading={isStatsLoading} value={data?.totalUsers} icon={LuUser} />
        </Col>
        <Col span={6}>
          <Stats label="Total Admins" isLoading={isStatsLoading} value={data?.totalAdmins} icon={TbUserCog} />
        </Col>
        <Col span={24}>
          <Users onRefresh={() => refetchStats()} />
        </Col>
      </Row>
    </MainLayout>
  );
};
