import L from 'leaflet';

export const centerPositionFromBounds = (bounds) => {
  let minX = bounds[0][0];
  let minY = bounds[0][1];
  let maxX = 0;
  let maxY = 0;
  bounds.forEach((b) => {
    if (b[0] < minX) minX = b[0];
    if (b[0] > maxX) maxX = b[0];
    if (b[1] < minY) minY = b[1];
    if (b[1] > maxY) maxY = b[1];
  });

  return [(minX + maxX) / 2, (minY + maxY) / 2];
};
export const getBoundsRect = (bounds) => {
  let southWest = L.latLng(bounds[0][0]);
  let northEast = L.latLng(bounds[0][1]);

  bounds.forEach((rect) => {
    const rectBounds = L.latLngBounds(rect[0], rect[1]);
    southWest = rectBounds.getSouthWest().min(southWest);
    northEast = rectBounds.getNorthEast().max(northEast);
  });

  const overallBounds = L.latLngBounds(southWest, northEast);
  return overallBounds;
};
