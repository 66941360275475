/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import store, { authSlice } from '../store';
import {
  decodeToken,
  getTokenExpiration,
  getUserTokenFromLocalStorage,
  saveUserTokenToLocalStorage,
} from './authHelpers';
import UserTokenRefresh from './UserTokenRefresh';
import EnsureAuth from './EnsureAuth';
import useAnonymousAuthFingerprint from './AnonymousAuthFingerprint/useAnonymousAuthFingerprint';
import { API_URL } from 'config';

const AuthProvider = ({ user, anonToken, tokenRefreshInProgress, children }) => {
  const [userAuthCompleted, setUserAuthCompleted] = useState(false);
  const [anonAuthCompleted, setAnonAuthCompleted] = useState(false);

  const { getAnonymousToken } = useAnonymousAuthFingerprint();

  const initialUserAuth = async () => {
    try {
      const token = await getUserTokenFromLocalStorage();
      if (token) {
        store.dispatch(
          authSlice.actions.setUser({
            // userdata: decodeToken(token),
            token_expiration: getTokenExpiration(token),
            access_token: token.access_token,
          }),
        );
      } else {
        const bodyData = new URLSearchParams({
          grant_type: 'client_credentials',
          client_secret: process.env.REACT_APP_COPERNICUS_CLIENT_SECRET,
          client_id: process.env.REACT_APP_COPERNICUS_CLIENT_ID,
        }).toString();

        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/copernicus_id/auth/realms/CDSE/protocol/openid-connect/token`,
          bodyData,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              // Cookie: 'SERVERID=keycloak002.waw2',
            },
          },
        );
        store.dispatch(
          authSlice.actions.setUser({
            // userdata: decodeToken(res.data),
            token_expiration: getTokenExpiration(res.data),
            access_token: res.data.access_token,
          }),
        );
        saveUserTokenToLocalStorage({
          access_token: res.data.access_token,
          domain: '/',
          session_state: res.data.session_state,
          token_type: res.data.token_type,
          expires_in: res.data.expires_in,
          id_token: '',
        });
      }
    } catch (err) {
      console.error(err);
    }
    setUserAuthCompleted(true);
  };

  const initialAnonymousAuth = async () => {
    await getAnonymousToken();
    setAnonAuthCompleted(true);
  };

  useEffect(() => {
    initialUserAuth();
    // initialAnonymousAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <EnsureAuth
        userAuthCompleted={userAuthCompleted}
        user={user}
        anonToken={anonToken}
        tokenRefreshInProgress={tokenRefreshInProgress}
        anonAuthCompleted={anonAuthCompleted}
      ></EnsureAuth> */}
      <UserTokenRefresh>{children}</UserTokenRefresh>
    </>
  );
};

const mapStoreToProps = (store) => ({
  anonToken: store.auth.anonToken,
  user: store.auth.user.userdata,
  tokenRefreshInProgress: store.auth.tokenRefreshInProgress,
});
export default connect(mapStoreToProps)(AuthProvider);
