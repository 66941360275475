import React from 'react';
import { OnboardingLayout } from 'layout';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, message } from 'antd';
import { useQuery } from 'react-query';
import { verifySignup } from 'api';
function useQueryParam() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export const VerifyPage = () => {
  let query = useQueryParam();

  const [messageApi, contextHolder] = message.useMessage();
  const history = useHistory();
  const { isLoading, data } = useQuery('verifySignup', () => verifySignup(query.get('key')), {
    onSuccess: () => {
      messageApi.open({
        type: 'success',
        content: 'Email Verified',
      });

      setTimeout(() => {
        history.push('/login');
      }, 3000);
    },
    onError: (error) => {
      messageApi.open({
        type: 'error',
        content: error?.response?.data?.message || error.message,
      });
    },
  });

  return (
    <OnboardingLayout>
      {contextHolder}
      <Card
        style={{
          width: 480,
          backgroundColor: 'rgba(10, 10, 10, 0.5)',
          backdropFilter: 'blur(4px)',
          height: 'fit-content',
        }}
      >
        {isLoading ? (
          <p>Verifying now...</p>
        ) : data ? (
          <>
            <p>Your're verified successfully.</p>
            <p>Now directing to the login page...</p>
          </>
        ) : null}
      </Card>
    </OnboardingLayout>
  );
};
