import { GoHome, GoStack, GoGear } from 'react-icons/go/index.js';
import { LiaUserCogSolid } from 'react-icons/lia';

export const mainMenuItems = [
  {
    id: 1,
    title: 'Dashboard',
    icon: GoHome,
    to: '/dashboard',
  },
  {
    id: 3,
    title: 'Data',
    icon: GoStack,
    to: '/data',
  },
  {
    id: 4,
    title: 'Settings',
    icon: GoGear,
    to: '/settings',
  },
];

export const adminMenuItems = [
  {
    id: 5,
    title: 'Admin',
    icon: LiaUserCogSolid,
    to: '/admin',
  },
];

let api_url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENV === 'production') api_url = '/api';

export const API_URL = api_url;

export const MARINE_TIME_RANGE = [
  { label: 'last 24 hours', value: 24 * 60 * 60 },
  { label: 'last 7 days', value: 24 * 60 * 60 * 7 },
  { label: 'last 30 days', value: 24 * 60 * 60 * 30 },
];
export const CONTINENT_DATA = ['Asia', 'Europe', 'Africa', 'Oceania', 'North America', 'South America'];
export const COUNTRY_DATA = [
  {
    country: 'Afghanistan',
    continent: 'Asia',
  },
  {
    country: 'Albania',
    continent: 'Europe',
  },
  {
    country: 'Algeria',
    continent: 'Africa',
  },
  {
    country: 'American Samoa',
    continent: 'Oceania',
  },
  {
    country: 'Andorra',
    continent: 'Europe',
  },
  {
    country: 'Angola',
    continent: 'Africa',
  },
  {
    country: 'Anguilla',
    continent: 'North America',
  },
  {
    country: 'Antigua and Barbuda',
    continent: 'North America',
  },
  {
    country: 'Argentina',
    continent: 'South America',
  },
  {
    country: 'Armenia',
    continent: 'Asia',
  },
  {
    country: 'Aruba',
    continent: 'North America',
  },
  {
    country: 'Australia',
    continent: 'Oceania',
  },
  {
    country: 'Austria',
    continent: 'Europe',
  },
  {
    country: 'Azerbaijan',
    continent: 'Asia',
  },
  {
    country: 'Bahamas',
    continent: 'North America',
  },
  {
    country: 'Bahrain',
    continent: 'Asia',
  },
  {
    country: 'Bangladesh',
    continent: 'Asia',
  },
  {
    country: 'Barbados',
    continent: 'North America',
  },
  {
    country: 'Belarus',
    continent: 'Europe',
  },
  {
    country: 'Belgium',
    continent: 'Europe',
  },
  {
    country: 'Belize',
    continent: 'North America',
  },
  {
    country: 'Benin',
    continent: 'Africa',
  },
  {
    country: 'Bermuda',
    continent: 'North America',
  },
  {
    country: 'Bhutan',
    continent: 'Asia',
  },
  {
    country: 'Bolivia',
    continent: 'South America',
  },
  {
    country: 'Bosnia and Herzegovina',
    continent: 'Europe',
  },
  {
    country: 'Botswana',
    continent: 'Africa',
  },
  {
    country: 'Bouvet Island',
    continent: 'Antarctica',
  },
  {
    country: 'Brazil',
    continent: 'South America',
  },
  {
    country: 'British Indian Ocean Territory',
    continent: 'Africa',
  },
  {
    country: 'Brunei',
    continent: 'Asia',
  },
  {
    country: 'Bulgaria',
    continent: 'Europe',
  },
  {
    country: 'Burkina Faso',
    continent: 'Africa',
  },
  {
    country: 'Burundi',
    continent: 'Africa',
  },
  {
    country: 'Cambodia',
    continent: 'Asia',
  },
  {
    country: 'Cameroon',
    continent: 'Africa',
  },
  {
    country: 'Canada',
    continent: 'North America',
  },
  {
    country: 'Cape Verde',
    continent: 'Africa',
  },
  {
    country: 'Cayman Islands',
    continent: 'North America',
  },
  {
    country: 'Central African Republic',
    continent: 'Africa',
  },
  {
    country: 'Chad',
    continent: 'Africa',
  },
  {
    country: 'Chile',
    continent: 'South America',
  },
  {
    country: 'China',
    continent: 'Asia',
  },
  {
    country: 'Christmas Island',
    continent: 'Oceania',
  },
  {
    country: 'Cocos (Keeling) Islands',
    continent: 'Oceania',
  },
  {
    country: 'Colombia',
    continent: 'South America',
  },
  {
    country: 'Comoros',
    continent: 'Africa',
  },
  {
    country: 'Congo',
    continent: 'Africa',
  },
  {
    country: 'Cook Islands',
    continent: 'Oceania',
  },
  {
    country: 'Costa Rica',
    continent: 'North America',
  },
  {
    country: 'Croatia',
    continent: 'Europe',
  },
  {
    country: 'Cuba',
    continent: 'North America',
  },
  {
    country: 'Cyprus',
    continent: 'Asia',
  },
  {
    country: 'Czech Republic',
    continent: 'Europe',
  },
  {
    country: 'Denmark',
    continent: 'Europe',
  },
  {
    country: 'Djibouti',
    continent: 'Africa',
  },
  {
    country: 'Dominica',
    continent: 'North America',
  },
  {
    country: 'Dominican Republic',
    continent: 'North America',
  },
  {
    country: 'East Timor',
    continent: 'Asia',
  },
  {
    country: 'Ecuador',
    continent: 'South America',
  },
  {
    country: 'Egypt',
    continent: 'Africa',
  },
  {
    country: 'El Salvador',
    continent: 'North America',
  },
  {
    country: 'England',
    continent: 'Europe',
  },
  {
    country: 'Equatorial Guinea',
    continent: 'Africa',
  },
  {
    country: 'Eritrea',
    continent: 'Africa',
  },
  {
    country: 'Estonia',
    continent: 'Europe',
  },
  {
    country: 'Ethiopia',
    continent: 'Africa',
  },
  {
    country: 'Falkland Islands',
    continent: 'South America',
  },
  {
    country: 'Faroe Islands',
    continent: 'Europe',
  },
  {
    country: 'Fiji Islands',
    continent: 'Oceania',
  },
  {
    country: 'Finland',
    continent: 'Europe',
  },
  {
    country: 'France',
    continent: 'Europe',
  },
  {
    country: 'French Guiana',
    continent: 'South America',
  },
  {
    country: 'French Polynesia',
    continent: 'Oceania',
  },
  {
    country: 'French Southern territories',
    continent: 'Antarctica',
  },
  {
    country: 'Gabon',
    continent: 'Africa',
  },
  {
    country: 'Gambia',
    continent: 'Africa',
  },
  {
    country: 'Georgia',
    continent: 'Asia',
  },
  {
    country: 'Germany',
    continent: 'Europe',
  },
  {
    country: 'Ghana',
    continent: 'Africa',
  },
  {
    country: 'Gibraltar',
    continent: 'Europe',
  },
  {
    country: 'Greece',
    continent: 'Europe',
  },
  {
    country: 'Greenland',
    continent: 'North America',
  },
  {
    country: 'Grenada',
    continent: 'North America',
  },
  {
    country: 'Guadeloupe',
    continent: 'North America',
  },
  {
    country: 'Guam',
    continent: 'Oceania',
  },
  {
    country: 'Guatemala',
    continent: 'North America',
  },
  {
    country: 'Guinea',
    continent: 'Africa',
  },
  {
    country: 'Guinea-Bissau',
    continent: 'Africa',
  },
  {
    country: 'Guyana',
    continent: 'South America',
  },
  {
    country: 'Haiti',
    continent: 'North America',
  },
  {
    country: 'Heard Island and McDonald Islands',
    continent: 'Antarctica',
  },
  {
    country: 'Vatican City State',
    continent: 'Europe',
  },
  {
    country: 'Honduras',
    continent: 'North America',
  },
  {
    country: 'Hong Kong',
    continent: 'Asia',
  },
  {
    country: 'Hungary',
    continent: 'Europe',
  },
  {
    country: 'Iceland',
    continent: 'Europe',
  },
  {
    country: 'India',
    continent: 'Asia',
  },
  {
    country: 'Indonesia',
    continent: 'Asia',
  },
  {
    country: 'Iran',
    continent: 'Asia',
  },
  {
    country: 'Iraq',
    continent: 'Asia',
  },
  {
    country: 'Ireland',
    continent: 'Europe',
  },
  {
    country: 'Israel',
    continent: 'Asia',
  },
  {
    country: 'Italy',
    continent: 'Europe',
  },
  {
    country: 'Ivory Coast',
    continent: 'Africa',
  },
  {
    country: 'Jamaica',
    continent: 'North America',
  },
  {
    country: 'Japan',
    continent: 'Asia',
  },
  {
    country: 'Jordan',
    continent: 'Asia',
  },
  {
    country: 'Kazakhstan',
    continent: 'Asia',
  },
  {
    country: 'Kenya',
    continent: 'Africa',
  },
  {
    country: 'Kiribati',
    continent: 'Oceania',
  },
  {
    country: 'Kuwait',
    continent: 'Asia',
  },
  {
    country: 'Kyrgyzstan',
    continent: 'Asia',
  },
  {
    country: 'Laos',
    continent: 'Asia',
  },
  {
    country: 'Latvia',
    continent: 'Europe',
  },
  {
    country: 'Lebanon',
    continent: 'Asia',
  },
  {
    country: 'Lesotho',
    continent: 'Africa',
  },
  {
    country: 'Liberia',
    continent: 'Africa',
  },
  {
    country: 'Libyan Arab Jamahiriya',
    continent: 'Africa',
  },
  {
    country: 'Liechtenstein',
    continent: 'Europe',
  },
  {
    country: 'Lithuania',
    continent: 'Europe',
  },
  {
    country: 'Luxembourg',
    continent: 'Europe',
  },
  {
    country: 'Macao',
    continent: 'Asia',
  },
  {
    country: 'North Macedonia',
    continent: 'Europe',
  },
  {
    country: 'Madagascar',
    continent: 'Africa',
  },
  {
    country: 'Malawi',
    continent: 'Africa',
  },
  {
    country: 'Malaysia',
    continent: 'Asia',
  },
  {
    country: 'Maldives',
    continent: 'Asia',
  },
  {
    country: 'Mali',
    continent: 'Africa',
  },
  {
    country: 'Malta',
    continent: 'Europe',
  },
  {
    country: 'Marshall Islands',
    continent: 'Oceania',
  },
  {
    country: 'Martinique',
    continent: 'North America',
  },
  {
    country: 'Mauritania',
    continent: 'Africa',
  },
  {
    country: 'Mauritius',
    continent: 'Africa',
  },
  {
    country: 'Mayotte',
    continent: 'Africa',
  },
  {
    country: 'Mexico',
    continent: 'North America',
  },
  {
    country: 'Micronesia, Federated States of',
    continent: 'Oceania',
  },
  {
    country: 'Moldova',
    continent: 'Europe',
  },
  {
    country: 'Monaco',
    continent: 'Europe',
  },
  {
    country: 'Mongolia',
    continent: 'Asia',
  },
  {
    country: 'Montenegro',
    continent: 'Europe',
  },
  {
    country: 'Montserrat',
    continent: 'North America',
  },
  {
    country: 'Morocco',
    continent: 'Africa',
  },
  {
    country: 'Mozambique',
    continent: 'Africa',
  },
  {
    country: 'Myanmar',
    continent: 'Asia',
  },
  {
    country: 'Namibia',
    continent: 'Africa',
  },
  {
    country: 'Nauru',
    continent: 'Oceania',
  },
  {
    country: 'Nepal',
    continent: 'Asia',
  },
  {
    country: 'Netherlands',
    continent: 'Europe',
  },
  {
    country: 'Netherlands Antilles',
    continent: 'North America',
  },
  {
    country: 'New Caledonia',
    continent: 'Oceania',
  },
  {
    country: 'New Zealand',
    continent: 'Oceania',
  },
  {
    country: 'Nicaragua',
    continent: 'North America',
  },
  {
    country: 'Niger',
    continent: 'Africa',
  },
  {
    country: 'Nigeria',
    continent: 'Africa',
  },
  {
    country: 'Niue',
    continent: 'Oceania',
  },
  {
    country: 'Norfolk Island',
    continent: 'Oceania',
  },
  {
    country: 'North Korea',
    continent: 'Asia',
  },
  {
    country: 'Northern Ireland',
    continent: 'Europe',
  },
  {
    country: 'Northern Mariana Islands',
    continent: 'Oceania',
  },
  {
    country: 'Norway',
    continent: 'Europe',
  },
  {
    country: 'Oman',
    continent: 'Asia',
  },
  {
    country: 'Pakistan',
    continent: 'Asia',
  },
  {
    country: 'Palau',
    continent: 'Oceania',
  },
  {
    country: 'Palestine',
    continent: 'Asia',
  },
  {
    country: 'Panama',
    continent: 'North America',
  },
  {
    country: 'Papua New Guinea',
    continent: 'Oceania',
  },
  {
    country: 'Paraguay',
    continent: 'South America',
  },
  {
    country: 'Peru',
    continent: 'South America',
  },
  {
    country: 'Philippines',
    continent: 'Asia',
  },
  {
    country: 'Pitcairn',
    continent: 'Oceania',
  },
  {
    country: 'Poland',
    continent: 'Europe',
  },
  {
    country: 'Portugal',
    continent: 'Europe',
  },
  {
    country: 'Puerto Rico',
    continent: 'North America',
  },
  {
    country: 'Qatar',
    continent: 'Asia',
  },
  {
    country: 'Reunion',
    continent: 'Africa',
  },
  {
    country: 'Romania',
    continent: 'Europe',
  },
  {
    country: 'Russia',
    continent: 'Europe',
  },
  {
    country: 'Rwanda',
    continent: 'Africa',
  },
  {
    country: 'Saint Helena',
    continent: 'Africa',
  },
  {
    country: 'Saint Kitts and Nevis',
    continent: 'North America',
  },
  {
    country: 'Saint Lucia',
    continent: 'North America',
  },
  {
    country: 'Saint Pierre and Miquelon',
    continent: 'North America',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    continent: 'North America',
  },
  {
    country: 'Samoa',
    continent: 'Oceania',
  },
  {
    country: 'San Marino',
    continent: 'Europe',
  },
  {
    country: 'Sao Tome and Principe',
    continent: 'Africa',
  },
  {
    country: 'Saudi Arabia',
    continent: 'Asia',
  },
  {
    country: 'Scotland',
    continent: 'Europe',
  },
  {
    country: 'Senegal',
    continent: 'Africa',
  },
  {
    country: 'Serbia',
    continent: 'Europe',
  },
  {
    country: 'Seychelles',
    continent: 'Africa',
  },
  {
    country: 'Sierra Leone',
    continent: 'Africa',
  },
  {
    country: 'Singapore',
    continent: 'Asia',
  },
  {
    country: 'Slovakia',
    continent: 'Europe',
  },
  {
    country: 'Slovenia',
    continent: 'Europe',
  },
  {
    country: 'Solomon Islands',
    continent: 'Oceania',
  },
  {
    country: 'Somalia',
    continent: 'Africa',
  },
  {
    country: 'South Africa',
    continent: 'Africa',
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    continent: 'Antarctica',
  },
  {
    country: 'South Korea',
    continent: 'Asia',
  },
  {
    country: 'South Sudan',
    continent: 'Africa',
  },
  {
    country: 'Spain',
    continent: 'Europe',
  },
  {
    country: 'Sri Lanka',
    continent: 'Asia',
  },
  {
    country: 'Sudan',
    continent: 'Africa',
  },
  {
    country: 'Suriname',
    continent: 'South America',
  },
  {
    country: 'Svalbard and Jan Mayen',
    continent: 'Europe',
  },
  {
    country: 'Swaziland',
    continent: 'Africa',
  },
  {
    country: 'Sweden',
    continent: 'Europe',
  },
  {
    country: 'Switzerland',
    continent: 'Europe',
  },
  {
    country: 'Syria',
    continent: 'Asia',
  },
  {
    country: 'Tajikistan',
    continent: 'Asia',
  },
  {
    country: 'Tanzania',
    continent: 'Africa',
  },
  {
    country: 'Thailand',
    continent: 'Asia',
  },
  {
    country: 'The Democratic Republic of Congo',
    continent: 'Africa',
  },
  {
    country: 'Togo',
    continent: 'Africa',
  },
  {
    country: 'Tokelau',
    continent: 'Oceania',
  },
  {
    country: 'Tonga',
    continent: 'Oceania',
  },
  {
    country: 'Trinidad and Tobago',
    continent: 'North America',
  },
  {
    country: 'Tunisia',
    continent: 'Africa',
  },
  {
    country: 'Turkey',
    continent: 'Asia',
  },
  {
    country: 'Turkmenistan',
    continent: 'Asia',
  },
  {
    country: 'Turks and Caicos Islands',
    continent: 'North America',
  },
  {
    country: 'Tuvalu',
    continent: 'Oceania',
  },
  {
    country: 'Uganda',
    continent: 'Africa',
  },
  {
    country: 'Ukraine',
    continent: 'Europe',
  },
  {
    country: 'United Arab Emirates',
    continent: 'Asia',
  },
  {
    country: 'United Kingdom',
    continent: 'Europe',
  },
  {
    country: 'United States of America',
    continent: 'North America',
  },
  {
    country: 'United States Minor Outlying Islands',
    continent: 'Oceania',
  },
  {
    country: 'Uruguay',
    continent: 'South America',
  },
  {
    country: 'Uzbekistan',
    continent: 'Asia',
  },
  {
    country: 'Vanuatu',
    continent: 'Oceania',
  },
  {
    country: 'Venezuela',
    continent: 'South America',
  },
  {
    country: 'Vietnam',
    continent: 'Asia',
  },
  {
    country: 'Virgin Islands, British',
    continent: 'North America',
  },
  {
    country: 'Virgin Islands, U.S.',
    continent: 'North America',
  },
  {
    country: 'Wales',
    continent: 'Europe',
  },
  {
    country: 'Wallis and Futuna',
    continent: 'Oceania',
  },
  {
    country: 'Western Sahara',
    continent: 'Africa',
  },
  {
    country: 'Yemen',
    continent: 'Asia',
  },
  {
    country: 'Zambia',
    continent: 'Africa',
  },
  {
    country: 'Zimbabwe',
    continent: 'Africa',
  },
];
export const MID_COUNTRY_DATA = {
  201: 'Albania',
  202: 'Andorra',
  203: 'Austria',
  204: 'Azores',
  205: 'Belgium',
  206: 'Belarus',
  207: 'Bulgaria',
  208: 'Vatican City State',
  209: 'Cyprus',
  210: 'Cyprus',
  211: 'Germany',
  212: 'Cyprus',
  213: 'Georgia',
  214: 'Moldova',
  215: 'Malta',
  216: 'Armenia',
  218: 'Germany',
  219: 'Denmark',
  220: 'Denmark',
  224: 'Spain',
  225: 'Spain',
  226: 'France',
  227: 'France',
  228: 'France',
  229: 'Malta',
  230: 'Finland',
  231: 'Faroe Islands',
  232: 'United Kingdom',
  233: 'United Kingdom',
  234: 'United Kingdom',
  235: 'United Kingdom',
  236: 'Gibraltar',
  237: 'Greece',
  238: 'Croatia',
  239: 'Greece',
  240: 'Greece',
  241: 'Greece',
  242: 'Morocco',
  243: 'Hungary',
  244: 'Netherlands',
  245: 'Netherlands',
  246: 'Netherlands',
  247: 'Italy',
  248: 'Malta',
  249: 'Malta',
  250: 'Ireland',
  251: 'Iceland',
  252: 'Liechtenstein',
  253: 'Luxembourg',
  254: 'Monaco',
  255: 'Madeira',
  256: 'Malta',
  257: 'Norway',
  258: 'Norway',
  259: 'Norway',
  261: 'Poland',
  262: 'Montenegro',
  263: 'Portugal',
  264: 'Romania',
  265: 'Sweden',
  266: 'Sweden',
  267: 'Slovakia',
  268: 'San Marino',
  269: 'Switzerland',
  270: 'Czech Republic',
  271: 'Turkey',
  272: 'Ukraine',
  273: 'Russia',
  274: 'Macedonia',
  275: 'Latvia',
  276: 'Estonia',
  277: 'Lithuania',
  278: 'Slovenia',
  279: 'Serbia',
  301: 'Anguilla',
  303: 'Alaska',
  304: 'Antigua and Barbuda',
  305: 'Antigua and Barbuda',
  306: 'Antilles',
  307: 'Aruba',
  308: 'Bahamas',
  309: 'Bahamas',
  310: 'Bermuda',
  311: 'Bahamas',
  312: 'Belize',
  314: 'Barbados',
  316: 'Canada',
  319: 'Cayman Islands',
  321: 'Costa Rica',
  323: 'Cuba',
  325: 'Dominica',
  327: 'Dominican Republic',
  329: 'Guadeloupe',
  330: 'Grenada',
  331: 'Greenland',
  332: 'Guatemala',
  335: 'Honduras',
  336: 'Haiti',
  338: 'United States of America',
  339: 'Jamaica',
  341: 'Saint Kitts and Nevis',
  343: 'Saint Lucia',
  345: 'Mexico',
  347: 'Martinique',
  348: 'Montserrat',
  350: 'Nicaragua',
  351: 'Panama',
  352: 'Panama',
  353: 'Panama',
  354: 'Panama',
  355: 'Panama',
  356: 'Panama',
  357: 'Panama',
  358: 'Puerto Rico',
  359: 'El Salvador',
  361: 'Saint Pierre and Miquelon',
  362: 'Trinidad and Tobago',
  364: 'Turks and Caicos Islands',
  366: 'United States of America',
  367: 'United States of America',
  368: 'United States of America',
  369: 'United States of America',
  370: 'Panama',
  371: 'Panama',
  372: 'Panama',
  373: 'Panama',
  374: 'Panama',
  375: 'Saint Vincent and the Grenadines',
  376: 'Saint Vincent and the Grenadines',
  377: 'Saint Vincent and the Grenadines',
  378: 'British Virgin Islands',
  379: 'United States Virgin Islands',
  401: 'Afghanistan',
  403: 'Saudi Arabia',
  405: 'Bangladesh',
  408: 'Bahrain',
  410: 'Bhutan',
  412: 'China',
  413: 'China',
  414: 'China',
  416: 'Taiwan',
  417: 'Sri Lanka',
  419: 'India',
  422: 'Iran',
  423: 'Azerbaijan',
  425: 'Iraq',
  428: 'Israel',
  431: 'Japan',
  432: 'Japan',
  434: 'Turkmenistan',
  436: 'Kazakhstan',
  437: 'Uzbekistan',
  438: 'Jordan',
  440: 'South Korea',
  441: 'South Korea',
  443: 'Palestine',
  445: 'North Korea',
  447: 'Kuwait',
  450: 'Lebanon',
  451: 'Kyrgyzstan',
  453: 'Macao',
  455: 'Maldives',
  457: 'Mongolia',
  459: 'Nepal',
  461: 'Oman',
  463: 'Pakistan',
  466: 'Qatar (State of)',
  468: 'Syrian Arab Republic',
  470: 'United Arab Emirates',
  472: 'Tajikistan',
  473: 'Yemen',
  475: 'Yemen',
  477: 'Hong Kong',
  478: 'Bosnia and Herzegovina',
  501: 'Adelie Land',
  503: 'Australia',
  506: 'Myanmar',
  508: 'Brunei Darussalam',
  510: 'Micronesia',
  511: 'Palau',
  512: 'New Zealand',
  514: 'Cambodia',
  515: 'Cambodia',
  516: 'Christmas Island',
  518: 'Cook Islands',
  520: 'Fiji',
  523: 'Cocos (Keeling) Islands',
  525: 'Indonesia',
  529: 'Kiribati',
  531: "Lao People's Democratic Republic",
  533: 'Malaysia',
  536: 'Northern Mariana Islands',
  538: 'Marshall Islands',
  540: 'New Caledonia',
  542: 'Niue',
  544: 'Nauru',
  546: 'French Polynesia',
  548: 'Philippines',
  553: 'Papua New Guinea',
  555: 'Pitcairn Island',
  557: 'Solomon Islands',
  559: 'American Samoa',
  561: 'Samoa',
  563: 'Singapore',
  564: 'Singapore',
  565: 'Singapore',
  566: 'Singapore',
  567: 'Thailand',
  570: 'Tonga',
  572: 'Tuvalu',
  574: 'Viet Nam',
  576: 'Vanuatu',
  577: 'Vanuatu',
  578: 'Wallis and Futuna Islands',
  601: 'South Africa',
  603: 'Angola',
  605: 'Algeria',
  607: 'Saint Paul and Amsterdam Islands',
  608: 'Ascension Island',
  609: 'Burundi',
  610: 'Benin',
  611: 'Botswana',
  612: 'Central African Republic',
  613: 'Cameroon',
  615: 'Congo',
  616: 'Comoros',
  617: 'Cabo Verde',
  618: 'Crozet Archipelago',
  619: 'Ivory Coast',
  620: 'Comoros',
  621: 'Djibouti',
  622: 'Egypt',
  624: 'Ethiopia',
  625: 'Eritrea',
  626: 'Gabonese Republic',
  627: 'Ghana',
  629: 'Gambia',
  630: 'Guinea-Bissau',
  631: 'Equatorial Guinea',
  632: 'Guinea',
  633: 'Burkina Faso',
  634: 'Kenya',
  635: 'Kerguelen Islands',
  636: 'Liberia',
  637: 'Liberia',
  638: 'South Sudan',
  642: 'Libya',
  644: 'Lesotho',
  645: 'Mauritius',
  647: 'Madagascar',
  649: 'Mali',
  650: 'Mozambique',
  654: 'Mauritania',
  655: 'Malawi',
  656: 'Niger',
  657: 'Nigeria',
  659: 'Namibia',
  660: 'Reunion',
  661: 'Rwanda',
  662: 'Sudan',
  663: 'Senegal',
  664: 'Seychelles',
  665: 'Saint Helena',
  666: 'Somali Democratic Republic',
  667: 'Sierra Leone',
  668: 'Sao Tome and Principe',
  669: 'Swaziland',
  670: 'Chad',
  671: 'Togolese Republic',
  672: 'Tunisian Republic',
  674: 'Tanzania',
  675: 'Uganda',
  676: 'Democratic Republic of the Congo',
  677: 'Tanzania',
  678: 'Zambia',
  679: 'Zimbabwe',
  701: 'Argentine Republic',
  710: 'Brazil',
  720: 'Bolivia',
  725: 'Chile',
  730: 'Colombia',
  735: 'Ecuador',
  740: 'Falkland Islands',
  745: 'Guiana',
  750: 'Guyana',
  755: 'Paraguay',
  760: 'Peru',
  765: 'Suriname',
  770: 'Uruguay',
  775: 'Venezuela',
};
