import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { EOBCCSlider } from '../../../../junk/EOBCommon/EOBCCSlider/EOBCCSlider';

import './CollectionForm.scss';
import { getODataCollectionInfoFromDatasetId } from '../../../../api/OData/ODataHelpers';

import SelectedFiltersList from './filters/SelectedFiltersList';
import { connect } from 'react-redux';
import { TABS } from '../../../../const';
import AdditionalFiltersToggle from './filters/AdditionalFiltersToggle';
import { getAllFiltersForCollection } from './filters/AdditionalFilters.utils';
import AdditionalFilters from './filters/AdditionalFilters';
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox';

const CLOUD_COVER_PERCENT = 100;

export const getCollectionFormInitialState = () => ({
  selectedCollections: {},
  maxCc: {},
  selectedFilters: {},
});

export const createCollectionFormFromDatasetId = (datasetId, params) => {
  const oDataCollectionInfo = getODataCollectionInfoFromDatasetId(datasetId, params);

  if (!oDataCollectionInfo) {
    return null;
  }

  const collectionForm = getCollectionFormInitialState();

  collectionForm.selectedCollections[oDataCollectionInfo.id] = {
    [oDataCollectionInfo.instrument]: {
      ...(oDataCollectionInfo.productType ? { [oDataCollectionInfo.productType]: {} } : {}),
    },
  };

  if (oDataCollectionInfo.maxCC) {
    collectionForm.maxCc = {
      [oDataCollectionInfo.id]: { [oDataCollectionInfo.instrument]: oDataCollectionInfo.maxCC },
    };
  }

  if (oDataCollectionInfo.selectedFilters) {
    collectionForm.selectedFilters = {
      [oDataCollectionInfo.id]: oDataCollectionInfo.selectedFilters,
    };
  }

  return collectionForm;
};

function CollectionForm({
  selectedCollections,
  maxCc,
  setSelectedCollections,
  setMaxCc,
  selectedFilters = {},
  setSelectedFilters,
  resetSelectedFilters,
  selectedTabIndex,
  resultsPanelSelected,
  additionFiltersPositionTop,
  setAdditionalFiltersPositionTop,
  isAdditionalFilters = true,
  collections,
}) {
  const [additionalFiltersCollection, setAdditionalFiltersCollection] = useState();

  useEffect(() => {
    //close filters panel if opened collection is no longer among selected collections
    if (
      additionalFiltersCollection &&
      selectedCollections &&
      !selectedCollections[additionalFiltersCollection]
    ) {
      setAdditionalFiltersCollection(null);
    }
  }, [selectedCollections, additionalFiltersCollection]);

  const onSourceChange = (sourceId) => {
    const source = collections.find((collection) => {
      return collection.id === sourceId;
    });
    const mustRemove = !!selectedCollections[sourceId];
    if (mustRemove) {
      const newSelectedOptionsObj = cloneDeep(selectedCollections);
      delete newSelectedOptionsObj[sourceId];
      setSelectedCollections(newSelectedOptionsObj);
    } else {
      let collections = {};
      source.collections.forEach((collection) => {
        if (collection?.instruments) {
          let instruments = {};
          collection.instruments.forEach((inst) => {
            const products = {};
            if (inst?.productTypes) {
              inst.productTypes.forEach((prd) => {
                products[prd.id] = true;
              });
              instruments[inst.id] = products;
            } else {
              instruments[inst.id] = true;
            }
          });
          collections[collection.id] = instruments;
        } else {
          collections[collection.id] = true;
        }
      });
      setSelectedCollections({
        ...selectedCollections,
        [sourceId]: collections,
      });
    }
  };
  const onCollectionChange = (collectionId, sourceId) => {
    const collection = collections
      .find((source) => source.id === sourceId)
      .collections.find((collection) => {
        return collection.id === collectionId;
      });
    const mustRemove = !!selectedCollections[sourceId][collectionId];
    if (mustRemove) {
      const newSelectedOptionsObj = cloneDeep(selectedCollections);
      delete newSelectedOptionsObj[sourceId][collectionId];
      setSelectedCollections(newSelectedOptionsObj);
    } else {
      if (collection?.instruments) {
        let instruments = {};
        collection.instruments.forEach((inst) => {
          const products = {};
          if (inst?.productTypes) {
            inst.productTypes.forEach((prd) => {
              products[prd.id] = true;
            });
            instruments[inst.id] = products;
          } else {
            instruments[inst.id] = true;
          }
        });
        setSelectedCollections({
          ...selectedCollections,
          [sourceId]: {
            ...selectedCollections?.[sourceId],
            [collectionId]: instruments,
          },
        });

        // let selectedInstruments = [];
        // //select instrument if there is only one child
        // if (collection && collection.instruments && collection.instruments.length === 1) {
        //   selectedInstruments.push(collection.instruments[0]);
        // } else {
        //   selectedInstruments = collection.instruments.filter((i) => !!i.selected);
        // }

        // selectedInstruments.forEach((selectedInstrument) => {
        //   setSelectedCollections({
        //     ...selectedCollections,
        //     // [collectionId]: {
        //     //   ...selectedCollections?.[collectionId],
        //     //   [selectedInstrument.id]: {},
        //     // },
        //     [collectionId]: instruments,
        //   });

        //   if (selectedInstrument.supportsCloudCover) {
        //     setMaxCc({
        //       ...maxCc,
        //       [collectionId]: {
        //         ...maxCc?.[collectionId],
        //         [selectedInstrument.id]: CLOUD_COVER_PERCENT,
        //       },
        //     });
        //   }
        // });

        //set default filters
        if (collection?.additionalFilters) {
          collection?.additionalFilters
            .filter((af) => af.defaultValue)
            .forEach((af) => setSelectedFilters(collectionId, af.id, af.defaultValue));
        }
      } else {
        setSelectedCollections({
          ...selectedCollections,
          [sourceId]: {
            ...selectedCollections?.[sourceId],
            [collectionId]: true,
          },
        });
      }
    }

    if (collection.supportsCloudCover) {
      if (mustRemove) {
        deleteMaxCc(collectionId);
      } else {
        setMaxCc({ ...maxCc, [collection.id]: CLOUD_COVER_PERCENT });
      }
    }
  };

  const onInstrumentChange = (value, collectionId, sourceId) => {
    const instrumentSupportsCloudCover = !!collections
      .find((collection) => collection.id === sourceId)
      .collections.find((collection) => collection.id === collectionId)
      .instruments.find((child) => child.id === value).supportsCloudCover;

    const mustRemove = !!selectedCollections[sourceId][collectionId][value];

    if (mustRemove) {
      const newObj = cloneDeep(selectedCollections);
      delete newObj[sourceId][collectionId][value];
      setSelectedCollections(newObj);
    } else {
      const collection = collections
        .find((collection) => collection.id === sourceId)
        .collections.find((collection) => {
          return collection.id === collectionId;
        });
      const inst = collection.instruments.find((inst) => inst.id === value);
      let products;
      if (inst?.productTypes) {
        products = {};
        inst.productTypes.forEach((prd) => {
          products[prd.id] = true;
        });
      } else {
        products = true;
      }

      setSelectedCollections({
        ...selectedCollections,
        [sourceId]: {
          ...selectedCollections?.[sourceId],
          [collectionId]: {
            ...selectedCollections?.[sourceId]?.[collectionId],
            [value]: products,
          },
        },
      });
    }

    if (instrumentSupportsCloudCover) {
      if (mustRemove) {
        deleteMaxCc(collectionId, value);
      } else {
        setMaxCc({
          ...maxCc,
          [collectionId]: {
            ...maxCc?.[collectionId],
            [value]: CLOUD_COVER_PERCENT,
          },
        });
      }
    }
  };

  const onProductTypeChange = (value, collectionId, instrumentId, sourceId) => {
    if (selectedCollections[sourceId][collectionId][instrumentId][value]) {
      const newObj = cloneDeep(selectedCollections);
      delete newObj[sourceId][collectionId][instrumentId][value];
      setSelectedCollections(newObj);
    } else {
      setSelectedCollections({
        ...selectedCollections,
        [sourceId]: {
          ...selectedCollections?.[sourceId],
          [collectionId]: {
            ...selectedCollections?.[sourceId]?.[collectionId],
            [instrumentId]: {
              ...selectedCollections?.[sourceId]?.[collectionId]?.[instrumentId],
              [value]: true,
            },
          },
        },
      });
    }
  };

  const deleteMaxCc = (collectionId, instrumentId) => {
    const newMaxCc = cloneDeep(maxCc);
    if (instrumentId !== undefined) {
      if (Object.keys(maxCc[collectionId]).length > 1) {
        delete newMaxCc[collectionId][instrumentId];
      } else {
        delete newMaxCc[collectionId];
      }
    } else {
      delete newMaxCc[collectionId];
    }

    setMaxCc(newMaxCc);
  };

  const onMaxCcChange = (value, collectionId, instrumentId) => {
    setMaxCc({
      ...maxCc,
      ...(instrumentId
        ? {
            [collectionId]: {
              ...maxCc?.[collectionId],
              [instrumentId]: value,
            },
          }
        : { [collectionId]: value }),
    });
  };

  const isAdditionalFiltersPanelOpen = (collectionId) => {
    //not on search tab
    if (selectedTabIndex !== TABS.SEARCH_TAB) {
      return false;
    }

    //displaying results
    if (resultsPanelSelected) {
      return false;
    }

    if (!additionalFiltersCollection) {
      return false;
    }

    return additionalFiltersCollection === collectionId;
  };

  return collections.map((source) => (
    <div className="source-group" key={`${source.id}`}>
      <div className="source">
        <CustomCheckbox
          checked={Object.keys(selectedCollections).includes(source.id)}
          onChange={() => {
            onSourceChange(source.id);
            setAdditionalFiltersCollection(null);
          }}
          label={source.label}
        />
      </div>
      {selectedCollections[source.id] !== undefined &&
        source.collections.map((collection) => {
          const isCollectionChecked = selectedCollections[source.id][collection.id];
          return (
            <div className="collection-group" key={`${source.id}-${collection.id}`}>
              <div className="collection">
                <CustomCheckbox
                  checked={isCollectionChecked}
                  onChange={() => {
                    onCollectionChange(collection.id, source.id);
                    setAdditionalFiltersCollection(null);
                  }}
                  label={collection.label}
                />
                {/* {isAdditionalFilters && (
                    <AdditionalFiltersToggle
                      isSelected={additionalFiltersCollection === collection.id}
                      onOpen={() => {
                        if (additionalFiltersCollection === collection.id) {
                          //close addition filter panel
                          setAdditionalFiltersCollection(null);
                        } else {
                          //check if collection is selected and select it if it is not
                          if (!Object.keys(selectedCollections).includes(collection.id)) {
                            onCollectionChange(collection.id);
                          }
                          //open addition filter panel for selected collection
                          setAdditionalFiltersCollection(collection.id);
                        }
                      }}
                      selectedCollections={selectedCollections}
                      setAdditionalFiltersPositionTop={setAdditionalFiltersPositionTop}
                      isDisplayed={
                        selectedTabIndex === TABS.SEARCH_TAB &&
                        !resultsPanelSelected &&
                        additionalFiltersCollection === collection.id
                      }
                      disabled={!collection.additionalFilters}
                    />
                  )} */}
              </div>
              {selectedCollections[source.id][collection.id] !== undefined && collection?.instruments && (
                <div className="instruments">
                  {collection.instruments.map((instrument) => {
                    const isInstrumentChecked = selectedCollections[source.id][collection.id][instrument.id];
                    return (
                      <div key={`${source.id}-${collection.id}-${instrument.id}`} className="instrument">
                        <CustomCheckbox
                          checked={isInstrumentChecked}
                          onChange={() => onInstrumentChange(instrument.id, collection.id, source.id)}
                          label={instrument.label}
                        />
                        <div className="product-type">
                          {isInstrumentChecked &&
                            instrument?.productTypes &&
                            instrument.productTypes.map((productType) => (
                              <CustomCheckbox
                                className={`custom-checkbox-product-container`}
                                key={`${source.id}-${collection.id}-${instrument.id}-${productType.id}`}
                                checked={
                                  selectedCollections[source.id][collection.id][instrument.id][productType.id]
                                }
                                // checked={true}
                                onChange={() =>
                                  onProductTypeChange(productType.id, collection.id, instrument.id, source.id)
                                }
                                label={productType.label}
                              />
                            ))}
                          {/* {isInstrumentChecked && instrument.supportsCloudCover && (
                              <div className="cloud-filter ">
                                <EOBCCSlider
                                  sliderWidth={120}
                                  cloudCoverPercentage={maxCc[collection.id][instrument.id]}
                                  onChange={(val) => onMaxCcChange(val, collection.id, instrument.id)}
                                />
                              </div>
                            )} */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {/* {selectedCollections[collection.id] !== undefined && collection.supportsCloudCover && (
                  <div className="cloud-filter ">
                    <EOBCCSlider
                      sliderWidth={120}
                      cloudCoverPercentage={maxCc[collection.id]}
                      onChange={(val) => onMaxCcChange(val, collection.id)}
                    />
                  </div>
                )} */}
              <SelectedFiltersList
                collectionId={collection.id}
                selectedFilters={selectedFilters?.[collection.id]}
                onChange={setSelectedFilters}
                allFilters={getAllFiltersForCollection(collection)}
              ></SelectedFiltersList>
              {isAdditionalFiltersPanelOpen(collection.id) && (
                <AdditionalFilters
                  onClose={() => setAdditionalFiltersCollection(null)}
                  title={collection.label}
                  collectionId={collection.id}
                  onChange={setSelectedFilters}
                  selectedFilters={selectedFilters?.[collection.id]}
                  allFilters={getAllFiltersForCollection(collection)}
                  onReset={() => resetSelectedFilters(collection.id)}
                  positionTop={additionFiltersPositionTop}
                />
              )}
            </div>
          );
        })}
    </div>
  ));
}
const mapStoreToProps = (store) => ({
  selectedTabIndex: store.tabs.selectedTabIndex,
  resultsPanelSelected: store.searchResults.resultsPanelSelected,
  selectedLanguage: store.language.selectedLanguage,
});

export default connect(mapStoreToProps, null)(CollectionForm);
