import axios from 'axios';
import { API_URL } from 'config';

const API = (opts = {}, optsHeader = {}) => {
  /*
  |--------------------------------------------------
  | Custom axios api
  |--------------------------------------------------
  */
  const token = localStorage.getItem('jwt');

  const defaultOptions = {
    ...opts,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      ...optsHeader,
    },
    mode: 'cors', // no-cors, *cors, same-origin
    // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };

  let baseURL = `${API_URL}`;
  const axiosApi = axios.create({
    baseURL,
    ...defaultOptions,
  });

  // axiosApi.interceptors.response.use(undefined, (error) => {
  //   if (error.response?.status === 401) {
  //     localStorage.removeItem("jwt");
  //     if (window.location.pathname !== "/login") {
  //       window.location.href = `/login?redirect=${window.location.href}`;
  //     }
  //   }
  //   return Promise.reject(error);
  // });

  return axiosApi;
};

export { API };
