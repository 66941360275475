import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import DashboardPage from './Cupernicus/index';
import {
  DataPage,
  ProfilePage,
  SettingsPage,
  LoginPage,
  SignupPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  VerifyPage,
  ChangePasswordPage,
  AdminPage,
  InvitePage,
  TaskPage,
  TaskInfoPage,
  MarineWatchPage,
} from 'pages';
import { ProtectedRoute, AdminRoute } from 'components';
import { AuthProvider } from 'contexts';

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path="/">
              <LoginPage />
            </Route>
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route exact path="/signup">
              <SignupPage />
            </Route>
            <Route exact path="/verify">
              <VerifyPage />
            </Route>
            <Route exact path="/forgotPassword">
              <ForgotPasswordPage />
            </Route>
            <Route exact path="/resetPassword">
              <ResetPasswordPage />
            </Route>
            <Route exact path="/invite">
              <InvitePage />
            </Route>

            <ProtectedRoute path="/marine-watch">
              <MarineWatchPage />
            </ProtectedRoute>
            <ProtectedRoute path="/dashboard">
              <DashboardPage />
            </ProtectedRoute>
            <ProtectedRoute path="/data">
              <DataPage />
            </ProtectedRoute>
            <ProtectedRoute path="/settings">
              <SettingsPage />
            </ProtectedRoute>
            <ProtectedRoute path="/profile">
              <ProfilePage />
            </ProtectedRoute>
            <ProtectedRoute path="/changePassword">
              <ChangePasswordPage />
            </ProtectedRoute>
            <ProtectedRoute path="/tasks">
              <TaskPage />
            </ProtectedRoute>
            <ProtectedRoute path="/task">
              <TaskInfoPage />
            </ProtectedRoute>
            <AdminRoute path="/admin">
              <AdminPage />
            </AdminRoute>
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
