/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, createContext } from 'react';
import { useLocalstorageState } from 'rooks';
// import { useNavigate } from 'react-router-dom';

const initialState = {
  isLoggedIn: false,
  userInfo: null,
  login: () => {},
  logout: () => {},
  githubLogin: () => {},
  updateUserInfo: () => {},
};

export const AuthContext = createContext(initialState);

export const useAuth = () => useContext(AuthContext);

const STORAGE_KEY = 'srs-user-state';

export const AuthProvider = ({ children }) => {
  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);
  // const navigate = useNavigate();

  useEffect(() => {
    // Always refresh when context is initialized
    refreshUserInfo();
  }, []);

  const login = async (payload) => {};

  const updateUserInfo = async (payload) => {
    set({ ...value, userInfo: payload });
  };

  const logout = () => {
    window.localStorage.removeItem('jwt');
    set({ ...value, isLoggedIn: false, userInfo: null });
    // navigate('/');
  };

  const refreshUserInfo = async () => {
    // Only when the token exists does the client fetch user
    if (localStorage.getItem('jwt')) {
      // const user = await getUser();
      // await updateUserInfo(user);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        updateUserInfo,
        userInfo: value?.userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
