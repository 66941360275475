import { ProForm, ProFormText, ProFormSelect } from '@ant-design/pro-components';
import { Alert, Modal, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { inviteUser } from 'api';

export const NewTask = ({ open, onOpenChange, onNewUser = () => {} }) => {
  const formRef = useRef();
  const [error, setError] = useState('');
  useEffect(() => {
    if (!open) {
      formRef.current?.resetFields();
      setError('');
    }
    return () => {};
  }, [open]);

  return (
    <Modal
      title={'New Task'}
      width="400px"
      open={open}
      onCancel={async () => {
        onOpenChange(false);
      }}
      onOpenChange={(visible) => {
        formRef.current?.resetFields();
        onOpenChange(visible);
      }}
      footer={null}
    >
      <ProForm
        formRef={formRef}
        onFinish={async (values) => {
          setError('');
          const res = await inviteUser(values);
          if (res.status === 'error') {
            setError(res.message);
          } else {
            onNewUser();
            onOpenChange(false);
            message.success('Created a new task successfully!');
          }
        }}
        submitter={{
          searchConfig: {
            submitText: 'Create',
          },
          render: (_, doms) => {
            // For some reason, submit and reset buttons are reversed after
            // setting submitText.
            return doms.reverse();
          },
        }}
      >
        <ProFormText
          name="name"
          label={'Name'}
          placeholder={'Name'}
          rules={[
            {
              required: true,
            },
          ]}
        />

        <ProFormSelect
          name="type"
          label={'Type'}
          rules={[
            {
              required: true,
            },
          ]}
          options={[
            {
              value: 'ship detection',
              label: 'Ship Detection',
            },
            {
              value: 'iceberg detection',
              label: 'Iceberg Detection',
            },
            {
              value: 'airplane detection',
              label: 'Airplane Detection',
            },
          ]}
        />

        {error && (
          <Alert
            style={{
              marginBottom: 24,
            }}
            message={error}
            type="error"
            showIcon
          />
        )}
      </ProForm>
    </Modal>
  );
};
