import React from 'react';
import { MainLayout } from 'layout';
import { Card, message, Row, Col } from 'antd';
import { ProForm, ProFormText } from '@ant-design/pro-components';
import { updateSettings } from 'api';
import { useAuth } from 'contexts';

export const SettingsPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { updateUserInfo, userInfo } = useAuth();
  const handleSubmit = async (params) => {
    try {
      await updateSettings({ ...params });
      updateUserInfo({ ...userInfo, ...params });
      messageApi.open({
        type: 'success',
        content: 'Updated your settings successfully!',
      });
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error?.response?.data?.message || error.message,
      });
    }
  };
  return (
    <MainLayout>
      {contextHolder}

      <Card
        style={{ maxWidth: 600, margin: '100px auto auto auto' }}
        display={'flex'}
        justifyContent={'center'}
        pt={4}
      >
        <ProForm
          layout={'horizontal'}
          grid={true}
          rowProps={{
            gutter: [16, 0],
          }}
          initialValues={{
            stacEndpoint: userInfo?.stacEndpoint,
            theme: userInfo?.theme,
          }}
          submitter={{
            searchConfig: {
              submitText: 'Save Changes',
            },
            resetButtonProps: false,
            render: (props, doms) => {
              return (
                <Row align={'middle'}>
                  <Col flex={'auto'}>
                    <div style={{ marginLeft: 'auto', width: 'fit-content' }}>{doms}</div>
                  </Col>
                </Row>
              );
            },
          }}
          onFinish={async (values) => {
            await handleSubmit(values);
          }}
          params={{}}
        >
          <h1 style={{ margin: '0 auto 20px auto' }}> Settings </h1>
          <ProFormText
            labelAlign="left"
            labelCol={{ span: 6 }}
            name="stacEndpoint"
            label={'Stac Endpoint'}
            placeholder={'First Name'}
            rules={[
              {
                required: true,
              },
            ]}
          />
          {/* <ProFormRadio.Group
            name="theme"
            label="Theme"
            labelCol={{ span: 6 }}
            labelAlign="left"
            options={[
              {
                label: 'Dark',
                value: 'dark',
              },
              {
                label: 'Light',
                value: 'light',
              },
            ]}
          /> */}
        </ProForm>
      </Card>
    </MainLayout>
  );
};
