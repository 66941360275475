import { ProForm, ProFormSelect, ProFormText } from '@ant-design/pro-components';
import { Alert, Modal, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { updateAdminMember } from 'api';

export const EditUser = ({ open, onOpenChange, onEditUser = () => {}, data }) => {
  const formRef = useRef();
  const [error, setError] = useState('');
  useEffect(() => {
    if (!open) {
      formRef.current?.resetFields();
      setError('');
    }

    return () => {};
  }, [open]);

  return (
    <Modal
      title={'Edit User'}
      width="400px"
      open={open}
      onFinish={async (values) => {}}
      onCancel={async () => {
        onOpenChange(false);
      }}
      onOpenChange={(visible) => {
        formRef.current?.resetFields();
        onOpenChange(visible);
      }}
      footer={null}
    >
      <ProForm
        formRef={formRef}
        initialValues={{
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          role: data.role,
          _id: data._id,
        }}
        onFinish={async (values) => {
          setError('');
          const res = await updateAdminMember(data._id, {
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
          });
          if (res.status === 'error') {
            setError(res.message);
          } else {
            onEditUser();
            onOpenChange(false);
            message.success('Updated the user successfully!');
          }
        }}
      >
        <ProFormText
          name="firstName"
          label={'First Name'}
          placeholder="First Name"
          rules={[
            {
              required: true,
            },
          ]}
        />
        <ProFormText
          name="lastName"
          label={'Last Name'}
          placeholder="Last Name"
          rules={[
            {
              required: true,
            },
          ]}
        />
        <ProFormText
          name="email"
          label={'Email'}
          placeholder="Email"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Email is not valid',
            },
          ]}
        />
        <ProFormSelect
          name="role"
          label={'Role'}
          rules={[
            {
              required: true,
            },
          ]}
          options={[
            {
              value: 'user',
              label: 'User',
            },
            {
              value: 'admin',
              label: 'Admin',
            },
          ]}
        />
        {error && (
          <Alert
            style={{
              marginBottom: 24,
            }}
            message={error}
            type="error"
            showIcon
          />
        )}
      </ProForm>
    </Modal>
  );
};
