import { ProForm, ProFormSelect, ProFormText } from '@ant-design/pro-components';
import { Alert, Modal, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { updateTaskById } from 'api';

export const EditTask = ({ open, onOpenChange, onEdit = () => {}, data }) => {
  const formRef = useRef();
  const [error, setError] = useState('');
  useEffect(() => {
    if (!open) {
      formRef.current?.resetFields();
      setError('');
    }

    return () => {};
  }, [open]);

  return (
    <Modal
      title={'Edit Task'}
      width="400px"
      open={open}
      onFinish={async (values) => {}}
      onCancel={async () => {
        onOpenChange(false);
      }}
      onOpenChange={(visible) => {
        formRef.current?.resetFields();
        onOpenChange(visible);
      }}
      footer={null}
    >
      <ProForm
        formRef={formRef}
        initialValues={{
          name: data.name,
          type: data.type,
          location: data.location,
          status: data.status,
          createdAt: data.createdAt,
        }}
        onFinish={async (values) => {
          setError('');
          const res = await updateTaskById(data._id, {
            name: values.name,
            type: values.type,
          });
          if (res.status === 'error') {
            setError(res.message);
          } else {
            onEdit();
            onOpenChange(false);
            message.success('Updated the task successfully!');
          }
        }}
      >
        <ProFormText
          name="name"
          label={'Name'}
          placeholder={'Name'}
          rules={[
            {
              required: true,
            },
          ]}
        />

        <ProFormSelect
          name="type"
          label={'Type'}
          rules={[
            {
              required: true,
            },
          ]}
          options={[
            {
              value: 'ship detection',
              label: 'Ship Detection',
            },
            {
              value: 'iceberg detection',
              label: 'Iceberg Detection',
            },
            {
              value: 'airplane detection',
              label: 'Airplane Detection',
            },
          ]}
        />
        <ProFormText name="location" label={'Location'} placeholder={'Location'} disabled />
        <ProFormText name="status" label={'Status'} placeholder={'Status'} disabled />
        <ProFormText name="createdAt" label={'Created At'} placeholder={'Created At'} disabled />
        {error && (
          <Alert
            style={{
              marginBottom: 24,
            }}
            message={error}
            type="error"
            showIcon
          />
        )}
      </ProForm>
    </Modal>
  );
};
