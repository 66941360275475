/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import { Button, Card, Col, Tooltip, Row, Modal } from 'antd';
import { getAdminMembers, deleteAdminMember } from 'api';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FaRegTrashAlt } from 'react-icons/fa';
import { format } from 'date-fns';
import { FiEdit3 } from 'react-icons/fi';
import { ProTable } from '@ant-design/pro-components';
import { PlusOutlined } from '@ant-design/icons';
import { NewUser, EditUser } from './components';
const { confirm } = Modal;

export const Users = ({ onRefresh = () => {} }) => {
  const actionRef = useRef();
  const [newUserVisible, setNewUserVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const fetchData = async (params, sorter) => {
    let query = {
      offset: (params.current - 1) * params.pageSize,
      limit: params.pageSize,
    };
    if (Object.keys(sorter).length > 0) {
      query.sortBy = Object.keys(sorter)[0];
      query.order = Object.values(sorter)[0] === 'ascend' ? 'asc' : 'desc';
    }
    let res = await getAdminMembers(query);

    return {
      success: true,
      data: res.result,
      total: res.total,
    };
  };

  const handleRemoveMember = async (data) => {
    confirm({
      title: 'Delete user',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${data.firstName}?`,
      onOk: async () => {
        await deleteAdminMember(data._id);
        onRefresh();
        actionRef.current.reload();
      },
    });
  };
  const handleEditMember = (data) => {
    setSelectedUser(data);
  };
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: true,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      sorter: true,
      render: (_, record) => <>{format(new Date(record.createdAt), 'MM/dd/yyyy hh:mm:ss')}</>,
    },
    {
      title: 'Action',
      filters: false,
      hideInSearch: true,
      sorter: false,
      render: (_, record) => (
        <Row gutter={[16, 16]}>
          <Col>
            <Tooltip>
              <Button icon={<FiEdit3 />} onClick={() => handleEditMember(record)}></Button>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip>
              <Button danger icon={<FaRegTrashAlt />} onClick={() => handleRemoveMember(record)}></Button>
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Card>
      <ProTable
        headerTitle={'Users'}
        actionRef={actionRef}
        rowKey="_id"
        pagination={{
          pageSize: 10,
        }}
        search={false}
        toolBarRender={() => [
          <Button type="primary" key="primary" onClick={() => setNewUserVisible(true)}>
            <PlusOutlined /> Invite
          </Button>,
        ]}
        request={fetchData}
        columns={columns}
        rowSelection={false}
        scroll={{ x: true }}
        options={{
          density: false,
        }}
      />

      <NewUser
        open={newUserVisible}
        onOpenChange={setNewUserVisible}
        onNewUser={() => {
          onRefresh();
          actionRef.current.reload();
        }}
      />
      {selectedUser && (
        <EditUser
          data={selectedUser}
          open={!!selectedUser}
          onOpenChange={() => setSelectedUser()}
          onEditUser={() => {
            onRefresh();
            actionRef.current.reload();
          }}
        />
      )}
    </Card>
  );
};
